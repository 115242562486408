import React, { useState, useMemo, useCallback } from "react";
import { FaSortDown, FaSortUp, FaSort } from "react-icons/fa";
import Moment from "react-moment";
import styled from "styled-components";
import { useSearchParams, useParams } from "react-router-dom";

import { useGetListQuestUsersQuery } from "services/questApi";
import defaultUserImage from "assets/images/dash-user.png";
import Pagination from "./Pagination";
import EmptyUsers from "pages/quest/QuestDetail/EmptyStates/EmptyUsers";

const Users = () => {
	let [searchParams] = useSearchParams();
	const page = searchParams.get("page") || 1;
	const [sortConfig, setSortConfig] = useState(null);
	const { id } = useParams();

	const queryParams = useMemo(() => {
		let query = { page, id };
		if (sortConfig) {
			return { ...query, sort: sortConfig.sort, field: sortConfig.field };
		}
		return query;
	}, [page, sortConfig, id]);

	const { data, isLoading } = useGetListQuestUsersQuery(queryParams);
	const { users, total, perPage } = data || {};
	const emptyUsers = users?.length === 0;

	const requestSort = (field) => {
		let sort = "asc";
		if (sortConfig && sortConfig.field === field && sortConfig.sort === "asc") {
			sort = "desc";
		}
		setSortConfig({ sort, field });
	};

	const sortIcon = useCallback(
		(field) => {
			if (sortConfig && sortConfig.field === field) {
				if (sortConfig.sort === "asc") {
					return <FaSortUp />;
				} else {
					return <FaSortDown />;
				}
			}
			return <FaSort />;
		},
		[sortConfig]
	);

	return (
		<>
			{ emptyUsers && <EmptyUsers /> }
			{ !emptyUsers && (
				<>
					<div className="place__table--wd">
						<div className="place__table">
							<table className="table">
								<thead>
									<tr>
										<th scope="col" onClick={() => requestSort("user_username")}>
											<div className="flex items-center">
												Username&nbsp;{sortIcon("user_username")}
											</div>
										</th>
										<th scope="col" onClick={() => requestSort("tasks_count")}>
											<div className="flex items-center">
												Progress&nbsp;{sortIcon("tasks_count")}
											</div>
										</th>
										<th scope="col" onClick={() => requestSort("points")}>
											<div className="flex items-center">
												Points&nbsp;{sortIcon("points")}
											</div>
										</th>
										<th scope="col" onClick={() => requestSort("created_at")}>
											<div className="flex items-center mx-2">
												Date&nbsp;Joined&nbsp;{sortIcon("created_at")}
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{!isLoading &&
										users?.map((user) => (
											<tr key={user.id}>
												<td>
													<div className="d-flex justify-content-around align-items-center">
														<div className="circle-img quest-badge">
															<img
																src={user.user_avatar_url || defaultUserImage}
																alt=""
															/>
														</div>
														{user.user_username}
													</div>
												</td>
												<td>
													<div className="progress__fx">
														<Progress
															className="progress"
															progress={user.progress}
														/>
														<div className="progress__ct">
															<span>{user.progress}</span>
														</div>
													</div>
												</td>
												<td>{user.points}</td>
												<td>
													{" "}
													<Moment format="MMM DD, YYYY  HH:mm">
														{user.created_at}
													</Moment>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="table__result">
						<Pagination
							isLoading={isLoading}
							total={total}
							currentPage={page}
							perPage={perPage}
						/>
					</div>
				</>
			)}
		</>
	);
};

const Progress = styled.div`
	&:before {
		width: ${({ progress }) => progress};
	}
`;

export default Users;
