import { baseApi } from "./baseApi";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: ["TaskPosition", "Task"],
});

const taskApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTask: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/tasks/${id}`,
      }),
      providesTags: (_result, _error, arg) => [{ type: "Task", id: arg }],
    }),
    createTask: builder.mutation({
      query: (data) => ({
        url: "/api/v1/creator/tasks",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, _error, _arg) => [
        { type: "Places", id: result.quest_id },
      ],
    }),
    updateTask: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/creator/tasks/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (result, _error, arg) => [
        { type: "Places", id: result.quest_id },
        { type: "Task", id: arg.id },
      ],
    }),
    deleteTask: builder.mutation({
      query: (id) => ({
        url: `/api/v1/creator/tasks/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [{ type: "Places", id: result?.quest_id }],
    }),
    listPositionTask: builder.query({
      query: ({ id, type }) => ({
        url: `/api/v1/creator/places/${id}/list_tasks?type=${type}`,
      }),
      providesTags: (_result, _error, arg) => [
        { type: "TaskPosition", id: arg },
      ],
    }),
    updateTaskPosition: builder.mutation({
      query: ({ id, type, data }) => ({
        url: `/api/v1/creator/places/${id}/position_tasks?type=${type}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ({ id }, _error, _arg) => [{ type: "Place", id }],
    }),
  }),
});

export const {
  useGetTaskQuery,
  useDeleteTaskMutation,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useListPositionTaskQuery,
  useUpdateTaskPositionMutation,
} = taskApi;
