import React from "react";
import { Link } from "react-router-dom";
import EnvelopeOpenIcon from "assets/images/EnvelopeOpen.png";
import Logo from "components/Logo";
const ChangePasswordEmail = () => {
  return (
    <div className="singin-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="singin-box">
              <div className="verify-box">
                <Logo />
                <div className="verify-containt">
                  <div className="verify-img">
                    <img src={EnvelopeOpenIcon} alt="" />
                  </div>
                  <div className="verify-text">
                    <h3>Check your email</h3>
                    <p>
                      We have sent a password recover instructions <br />
                      to your email.
                    </p>
                    <p>
                      If you have not received the email after a few <br />{" "}
                      minutes, please check your spam folder
                    </p>
                  </div>
                </div>
                <div className="verify-box-last-text">
                  <p>
                    Still not receiving email?&nbsp;
                    <Link to="/forgot-password">Try another email address</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordEmail;
