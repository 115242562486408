import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsClock } from "react-icons/bs";
import NumberedListIcon from "assets/images/icon-numbered-list.svg";
import EditIcon from "assets/images/icon/Edit.png";
import CurrencyIcon from "assets/images/icon/CurrencyCircleDollar.png";
import MapIcon from "assets/images/icon/map-marker-check-outline.png";
import ClipboardIcon from "assets/images/icon/clipboard-check-outline.png";
import CcIcon from "assets/images/icon/cc2.png";
import HTMLView from "components/HTMLView";
import ListPlace from "./ListPlace";
import classNames from "classnames";

const OverviewForm = () => {
  const { quest: data } = useSelector((state) => state.quest);

  let isFree = true;
  if (data) {
    isFree = data?.price === 0;
  }

  return (
    <div className="info-containt">
      <div className="info-title">
        <h3>
          <span>5</span> Overview
        </h3>
      </div>
      {data && (
        <>
          <div className="bat-area">
            <div className="bat-title">
              <h3>{data.title}</h3>
              <Link
                to={`/quests/${data?.slug}/edit`}
                className="flex items-center"
              >
                <img src={EditIcon} alt="" />
                Edit Section
              </Link>
            </div>
            <div className="bat-box">
              <div className="bat-img">
                <img src={data.image_url} alt="" />
                <div className="profile-img circle-img">
                  <img src={data.badge?.image?.url} alt="" />
                </div>
              </div>
              <div className="bat-containt">
                <div className="flex items-center">
                  <p className="flex items-center font-semibold mr-4">
                    <img
                      src={CurrencyIcon}
                      alt=""
                      className={classNames("", {
                        green: !isFree,
                      })}
                    />
                    &nbsp; {isFree ? "Free" : data.price}
                  </p>
                  {data.place_sequential && (
                    <p className="flex items-center font-semibold mr-4">
                      <img src={NumberedListIcon} alt="" />
                      &nbsp; Place Sequential
                    </p>
                  )}
                  {data.time_tracker && (
                    <p className="flex items-center font-semibold">
                      <BsClock />
                      &nbsp; Time Tracker
                    </p>
                  )}
                </div>
                <div className="bat-text">
                  <HTMLView content={data.description} />
                </div>
                <div className="bat-info-fl">
                  <div className="bat-single-info">
                    <img src={MapIcon} alt="" />
                    <p>Check Point</p>
                    <span>{data.places_count}</span>
                  </div>
                  <div className="bat-single-info">
                    <img src={ClipboardIcon} alt="" />
                    <p>Task</p>
                    <span>{data.tasks_count}</span>
                  </div>
                  <div className="bat-single-info">
                    <img src={CcIcon} alt="" />
                    <p>Route</p>
                    <span>
                      3.5 <sub>km</sub>
                    </span>
                  </div>
                </div>
                <div className="bat-hight">
                  <h3>Highlights</h3>
                  <ul>
                    {data.highlights.map((highlight, index) => (
                      <li key={index}>{highlight}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="bat-area">
              <div className="bat-title">
                <h3>Intro</h3>
                <Link
                  to={`/quests/${data?.slug}/edit/intro`}
                  className="flex items-center"
                >
                  <img src={EditIcon} alt="" />
                  Edit Section
                </Link>
              </div>
              <div className="intro-area">
                {data.intro_image_url && (
                  <div className="intro-img">
                    <img src={data.intro_image_url} alt="" />
                  </div>
                )}
                <div className="intro-containt">
                  <div className="intro-top-text">
                    <HTMLView content={data.intro} />
                  </div>
                  {data.quest_intros.map((intro) => (
                    <div
                      className="intro-single-text"
                      key={`intro-${intro.id}`}
                    >
                      <h3>{intro.title}</h3>
                      <HTMLView content={intro.description} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bat-area">
            <div className="bat-title">
              <h3>Places & Tasks</h3>
              <Link
                to={`/quests/${data?.slug}/edit/place`}
                className="flex items-center"
              >
                <img src={EditIcon} alt="" />
                Edit Section
              </Link>
            </div>
            <ListPlace id={data?.id} />
          </div>
          <div className="bat-area">
            <div className="bat-title">
              <h3>Conclusion</h3>
              <Link
                to={`/quests/${data?.slug}/edit/conclusion`}
                className="flex items-center"
              >
                <img src={EditIcon} alt="" />
                Edit Section
              </Link>
            </div>
            <div className="Conclusion-area">
              <HTMLView content={data.conclusion?.description} />
            </div>
          </div>
        </>
      )}
      <div className="sinfo-form">
        <div className="quest-btn">
          <Link to={`/quests/${data?.slug}`} className="button">
            See your quest
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OverviewForm;
