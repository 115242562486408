import { baseApi } from "./baseApi";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: ["PlacePosition", "PlaceTasks"],
});

const placeApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    listPositionPlace: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/quests/${id}/list_places`,
      }),
      providesTags: (_result, _error, arg) => [
        { type: "PlacePosition", id: arg },
      ],
    }),
    updatePlacePosition: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/creator/quests/${id}/position_places`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "PlacePosition", id: arg.id },
        { type: "Places", id: arg.id },
      ],
    }),
    deletePlace: builder.mutation({
      query: (id) => ({
        url: `/api/v1/creator/places/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [{ type: "Places", id: result?.quest_id }],
    }),
    getPlaceTasks: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/places/${id}/tasks`,
      }),
      providesTags: (_result, _error, arg) => [{ type: "PlaceTasks", id: arg }],
    }),
  }),
});

export const {
  useListPositionPlaceQuery,
  useUpdatePlacePositionMutation,
  useDeletePlaceMutation,
  useGetPlaceTasksQuery,
} = placeApi;
