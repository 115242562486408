import React from "react";
import emptyPlaceListIcon from "assets/images/icon-empty-place-list.svg";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";

const EmptyPlaces = () => {
  const { quest } = useSelector((state) => state.quest);

	let navigateUrl = `/quests/${quest?.slug}/edit/place`;
	if (quest?.step === 'quest_info'){
		navigateUrl = `/quests/${quest?.slug}/edit/intro`;
	};

	return (
		<div className="dash-tab-containt py-7">
			<div className="dash-tab-img grid justify-center">
				<img src={emptyPlaceListIcon} alt="" />
			</div>
			<div className="dash-tab-text mb-4">
				<h3>No places has been added</h3>
				<p>Add places for user to play</p>
			</div>
      <div className="dash-tab-btn">
        <Link
          to={navigateUrl}
        >
        	<FaPlus />&nbsp;
          Add Place
        </Link>
			</div>
		</div>
	);
};

export default EmptyPlaces;
