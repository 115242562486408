import React from "react";
import loginIcon from "assets/images/icon/Login.png";
import noRecentUserIcon from "assets/images/icon/ClockCounterClockwise.png";
import defaultUserImage from "assets/images/dash-user.png";
import { useLastUserJoinedQuery } from "services/questApi";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const RecentUserJoin = () => {
	const { data, isLoading } = useLastUserJoinedQuery();

	return (
		<>
			<div className="dash-tab-ri-btn">
				<a href="#0">
					<img src={loginIcon} alt="" />
					Recent User Join
				</a>
			</div>
			{isLoading ? (
				<SkeletonRecentUserJoin />
			) : data?.length > 0 ? (
				<div className="dash-join">
					{data.map((questUser) => (
						<div className="single-join" key={questUser.id}>
							<div className="join-img">
								<img
									width={48}
									height={48}
									src={
										questUser?.user_avatar_url
											? questUser?.user_avatar_url
											: defaultUserImage
									}
									alt=""
								/>
							</div>
							<div className="join-text">
								<h3>{questUser?.user_username}</h3>
								<p>
									joined{" "}
									<Link to={`/quests/${questUser.quest_id}`}>
										{questUser.quest_title}
									</Link>
								</p>
								<p className="date-time">
									<Moment format="DD MMM . HH:mm">
										{questUser.created_at}
									</Moment>
								</p>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="dash-tab-ri-jion-time">
					<div className="flex justify-center">
						<img src={noRecentUserIcon} alt="" />
					</div>
					<p>No user joined in this recent time</p>
				</div>
			)}
		</>
	);
};

const SkeletonRecentUserJoin = () => {
	return (
		<div className="dash-join">
			<div className="single-join">
				<div className="join-img">
					<Skeleton circle height={48} width={48} />
				</div>
				<div className="join-text">
					<h3>
						<Skeleton width={100} />
					</h3>
					<p>
						<Skeleton width={100} />
					</p>
					<span>
						<Skeleton width={100} />
					</span>
				</div>
			</div>
		</div>
	);
};

export default RecentUserJoin;
