import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const TaskClue = ({ taskData }) => {
  const [isOpenImageClue, setIsOpenImageClue] = useState(false);
  const image = taskData.task_clue?.image?.url;
  return (
    <>
      {taskData.task_clue && (
        <div className="hints__wrap re--sec">
          <h4>Clue Reward 📜</h4>
          <div className="reward__wp">
            <div className="rewards__lf">
              <div className="rewards__img">
                <img
                  src={image}
                  alt=""
                  height={80}
                  width={80}
                  onClick={() => setIsOpenImageClue(true)}
                  style={{ cursor: "pointer" }}
                />
                {isOpenImageClue && (
                  <Lightbox
                    mainSrc={image}
                    onCloseRequest={() => setIsOpenImageClue(false)}
                  />
                )}
              </div>
              <div className="reward__ct">
                <p>{taskData.task_clue.title}</p>
              </div>
            </div>
            <div className="reward__search">
              <span>
                <FaSearch />
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskClue;
