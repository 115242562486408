import React, { forwardRef, useMemo } from "react";
import Select from "react-select";

const SelectInput = forwardRef((props, ref) => {
  const { value, options, onChange, overridingOnChange = false } = props;

  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
      width: "100%",
    }),
    control: (base) => ({
      ...base,
      borderRadius: "24px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "50px",
      padding: "0 6px",
    }),
    singleValue: (base) => ({
      ...base,
      margin: "0 10px",
    }),
    input: (base) => ({
      ...base,
      margin: "0 10px",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "24px",
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: "24px",
    }),
  };

  const selectedValue = useMemo(() => {
    return options?.find((option) => option.value === value);
  }, [value, options]);

  const selectProps = useMemo(() => {
    const onSelectChange = (option) => {
      onChange(option?.value);
    };

    if (overridingOnChange) {
      return props;
    } else {
      return { ...props, onChange: onSelectChange };
    }
  }, [overridingOnChange, props, onChange]);

  return (
    <Select
      inputRef={ref}
      styles={styles}
      isSearchable={true}
      {...selectProps}
      value={selectedValue}
    />
  );
});

export default SelectInput;
