import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import defaultUserImage from "assets/images/dash-user.png";
import { FaTrash } from "react-icons/fa";

const AvatarInput = React.forwardRef((props, ref) => {
  const fileRef = useRef();
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState(null);
  const { onChange, onError, name, value, error } = props;

  const onBrowseClick = () => {
    fileRef.current.click();
  };
  useEffect(() => {
    if (value && value?.constructor === String && value !== "SERVER_FILE") {
      setImage(value);
      onChange("SERVER_FILE");
    }
    if (files && !value) {
      onChange(files);
    }
  }, [value, onChange, files]);

  const handleFile = (files) => {
    let imageError = false;
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (files) {
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      if (onError) {
        if (file.size / 1024 > 5120) {
          imageError = true;
          onError(name, { type: "file_size", message: "File size is too big" });
        }
        if (validTypes.indexOf(file.type) === -1) {
          imageError = true;
          onError(name, { type: "file_type", message: "Invalid file type" });
        }
      }
      if (!imageError) {
        setImage(imageUrl);
        setFiles(files);
        onChange(files);
      }
    }
  };

  const onImageRemove = () => {
    setImage(null);
    onChange("");
  };

  const onFileInputChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      handleFile(event.target.files);
    }
  };

  const onFileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files?.length > 0) {
      handleFile(files);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className={classNames("avatar-circle drop-zone image-input", {
        "invalid-drop-zone": error,
      })}
      style={
        image && {
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
        }
      }
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={onFileDrop}
    >
      <input
        type="file"
        className="drop-zone__input"
        ref={(e) => {
          ref(e);
          fileRef.current = e;
        }}
        onChange={onFileInputChange}
      />
      {image && (
        <div className="overlay-avatar">
          <a href="#0" onClick={onImageRemove}>
            <FaTrash />
          </a>
        </div>
      )}

      {!image && (
        <div onClick={onBrowseClick} style={{ width: "100px" }}>
          <img src={defaultUserImage} alt="" />
        </div>
      )}
    </div>
  );
});

export default AvatarInput;
