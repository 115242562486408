import React, { useMemo, useState, useEffect } from "react";

const Cover = ({ task }) => {
	const { covers } = task;
	const [cover, setCover] = useState(null);

	const CoverList = ({ cover }) => {
		const { file_url, cover_type } = cover;
		const coverUrl = useMemo(() => {
			if (cover_type === "image") {
				return file_url;
			}
			return require("assets/images/view__task/v3.png");
		}, [cover_type, file_url]);
		return (
			<div
				className="view__task__sm--img"
				onClick={() => setCover(cover)}
				style={{ cursor: "pointer" }}
			>
				<img src={coverUrl} alt="" />
			</div>
		);
	};

	useEffect(() => {
		if (covers) {
			setCover(covers[0]);
		}
	}, [covers]);

	return (
		<>
			{cover && <CoverView cover={cover} />}
			<div className="view__task__btm__sm">
				{covers.map((cover) => (
					<CoverList cover={cover} key={cover.id} />
				))}
			</div>
		</>
	);
};

const CoverView = ({ cover }) => {
	const { file_url, cover_type } = cover;
	return (
		<div className="view__task__top--img">
			{cover_type === "image" && <img src={file_url} alt="" />}
			{cover_type === "audio" && (
				<audio controls autoPlay style={{ width: "100%" }}>
					<source src={file_url} />
				</audio>
			)}
			{cover_type === "video" && (
				<video
					style={{ height: "auto", width: "100%" }}
					src={file_url}
					controls
				></video>
			)}
		</div>
	);
};

export default Cover;
