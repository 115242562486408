import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active: false,
  header: "HOME",
  headerTitle: "",
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setActive: (state) => {
      state.active = !state.active;
    },
    setInActive: (state) => {
      state.active = false;
    },
    setHeader: (state, { payload }) => {
      state.header = payload;
    },
    setHeaderTitle: (state, { payload }) => {
      state.headerTitle = payload;
    },
    resetHeader: (state) => {
      state.header = "Home";
      state.headerTitle = "";
    },
  },
});

export const {
  setActive,
  setInActive,
  setHeader,
  resetHeader,
  setHeaderTitle,
} = navigationSlice.actions;
export default navigationSlice.reducer;
