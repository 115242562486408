import React, { useMemo } from "react";
import { Modal as ModalBootstrap } from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import Skeleton from "react-loading-skeleton";
import { useCloseModal } from "utils/questModal";
import ModalErrorBoundary from "./error/ModalErrorBoundary";

const Modal = () => {
	const { show, component, props } = useSelector((state) => state.modal);
	const closeModal = useCloseModal();

	const Component = useMemo(() => {
		const SelectedComponent = loadable(
			() => import(`./quest/modalForm/${component}`),
			{
				fallback: <EmptyModal />,
			}
		);
		if (SelectedComponent) {
			return <SelectedComponent {...props} />;
		}
	}, [props, component]);

	return (
		<div className="place--popup">
			<ModalBootstrap show={show} onHide={closeModal}>
				<button
					type="button"
					onClick={closeModal}
					data-bs-dismiss="modal"
					aria-label="Close"
				>
					<CgClose />
				</button>
				<ModalBootstrap.Body>
					<ModalErrorBoundary>{Component}</ModalErrorBoundary>
				</ModalBootstrap.Body>
			</ModalBootstrap>
		</div>
	);
};

export const EmptyModal = ({ message }) => {
	const closeModal = useCloseModal();
	return (
		<div className="place--wrap">
			<div className="place__top">{!message && <Skeleton width={150} />}</div>
			<div className="place__upload--box">
				{message ? (
					<h3>{message}</h3>
				) : (
					<div>
						<div className="place__input--box">
							{Array.from({ length: 10 }).map((_, index) => (
								<Skeleton key={`skleton-modal-${index}`} />
							))}
						</div>
					</div>
				)}
				<div className="map__btn">
					<a href="#0" onClick={closeModal} className="cancel">
						Cancel
					</a>
				</div>
			</div>
		</div>
	);
};

export default Modal;
