import React from "react";
import emptyPlaceListIcon from "assets/images/icon-empty-place-list.svg";

const EmptyPlaceList = () => {
	return (
		<div className="dash-tab-containt py-7">
		  <div className="dash-tab-img grid justify-center">
				<img src={emptyPlaceListIcon} alt="" />
			</div>
			<div className="dash-tab-text mb-4">
			  <h3>No places has been added</h3>
		    <p>Add places for user to play</p>
			</div>
		</div>
	);
};

export default EmptyPlaceList;
