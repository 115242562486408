import React, { useEffect } from "react";
import QuestNavigation from "components/quest/QuestNavigation";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHeader, setHeaderTitle } from "features/navigationSlice";
import { unSetQuest, setAction } from "features/questSlice";
const QuestNew = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHeader("QUEST"));
    dispatch(setHeaderTitle("Add Quest"));
    dispatch(unSetQuest());
    dispatch(setAction("NEW"));
  }, [dispatch]);

  return (
    <div className="main-content-body">
      <div className="info-area">
        <Outlet />
        <QuestNavigation />
      </div>
    </div>
  );
};

export default QuestNew;
