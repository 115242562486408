import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "components/Logo";
import AuthNotice from "components/AuthNotice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useResetPasswordMutation } from "services/authApi";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("reset_password_token");
  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const onSubmit = async (newPasswordData) => {
    try {
      const result = await resetPassword({
        ...newPasswordData,
        reset_password_token: resetPasswordToken,
      }).unwrap();
      if (result) {
        navigate("/reset-success");
      }
    } catch ({ data }) {
      setError(data?.errors);
    }
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="singin-area">
      {error && <AuthNotice type="error" message={error} />}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="singin-box hig">
              <Logo />
              <div className="singin-title">
                <h3>Create new password</h3>
              </div>
              <div className="singin-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="single-input">
                    <label>Password</label>
                    <div className="single-input-eye">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="Enter new password"
                        {...register("password", {
                          required: "Password is required.",
                        })}
                      />
                      <a
                        href="#0"
                        className={`cc fa fa-fw field-icon toggle-password
                          ${ errors.password && "is-invalid"}
                        `}
                        onClick={handleShowPassword}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </a>
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="single-input">
                    <label>Confirm Password</label>
                    <div className="single-input-eye">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className={`form-control ${
                          errors.password_confirmation ? "is-invalid" : ""
                        }`}
                        placeholder="Confirm new password"
                        {...register("password_confirmation", {
                          required: "Confirm Password is required.",
                          validate: (value) =>
                            value === password.current ||
                            "Confirm Password doesn't match Password",
                        })}
                      />
                      <a
                        href="#0"
                        className={`cc fa fa-fw field-icon toggle-password
                          ${ errors.password_confirmation && "is-invalid"}
                        `}
                        onClick={handleShowPassword}
                      >
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEyeSlash : faEye}
                        />
                      </a>
                      {errors.password_confirmation && (
                        <div className="invalid-feedback">
                          {errors.password_confirmation.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="singin-btn">
                    <button type="submit">Reset Password</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
