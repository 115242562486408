import React from "react";
import { useSelector } from "react-redux";
import { FaBars } from "react-icons/fa";
import { CgChevronRight, CgClose } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { setActive } from "features/navigationSlice";
import { Link } from "react-router-dom";

function Header() {
  const { header, active } = useSelector((state) => state.navigation);
  const dispatch = useDispatch();
  return (
    <div className="dash-breadcum">
      {header === "QUEST" || header === "SETTING" ? (
        <QuestHeader />
      ) : (
        <HomeHeader />
      )}

      <div className="header___bar">
        <a href="#0" onClick={() => dispatch(setActive())}>
          {active ? <CgClose /> : <FaBars />}
        </a>
      </div>
    </div>
  );
}

const HomeHeader = () => {
  return <p>Hi Creator! Welcome to Questeon 👋</p>;
};

const QuestHeader = () => {
  const { header, headerTitle } = useSelector((state) => state.navigation);
  const { quest } = useSelector((state) => state.quest);

  return (
    <div className="breadcum-fl">
      {(() => {
        switch (header) {
          case "QUEST":
            return <Link to="/quests">Quest</Link>;
          case "SETTING":
            return <Link to="/setting">Setting</Link>;
          default:
            return null;
        }
      })()}
      <span>
        <CgChevronRight />
      </span>
      {quest ? (
        <Link to={`/quests/${quest.slug}`}>{quest.title}</Link>
      ) : (
        headerTitle
      )}
    </div>
  );
};

export default Header;
