import QInfo from "components/QInfo";
import React from "react";
import image from "assets/images/505.svg";

function ErrorPage() {
  return (
    <QInfo
      title="Sorry! Unexpected Error"
      subtitle="We are working on fixing the problem. Please try again in a few minutes"
      image={image}
      buttonRoute="/"
      buttonText="Try Again Later"
    />
  );
}

export default ErrorPage;
