import React, { useState }  from "react";
import { useConfirmEmailQuery } from "services/authApi";
import { useSearchParams } from "react-router-dom";
import useWindowDimensions from "hooks/WindowDimensions";
import QuesteonLogo from "assets/images/questeon_logo.svg";
import ConfirmedLogo from "assets/images/confirmation.svg";
import ExpiredLogo from "assets/images/expired.svg";
import { Link } from "react-router-dom";
import { useFindEmailQuery, useSendEmailConfirmationMutation } from "services/authApi";
import AuthNotice from "components/AuthNotice";

const Confirmation = () => {
	const [searchParams] = useSearchParams();
	const confirmationToken = searchParams.get("confirmation_token");
	const { data, error } = useConfirmEmailQuery(confirmationToken);
	const { height } = useWindowDimensions();
	const { data: emailData } = useFindEmailQuery(confirmationToken);
	const [resendConfirmation] = useSendEmailConfirmationMutation();
	const tokenExpired = error?.data?.errors?.includes("new");
	const tokenInvalid = error?.data?.errors?.includes("invalid");
	
  const [resendNotice, setResendNotice] = useState({
    type: "",
    message: ""
  });

	let confirmationLogo = ConfirmedLogo;
	if (tokenExpired || tokenInvalid) {
		confirmationLogo = ExpiredLogo;
	};

	const onSubmit = async () => {
		try {
     	const result = await resendConfirmation({
      	email: emailData?.email,
      }).unwrap();
      if(result){
      	setResendNotice({
        	type: "success",
        	message: "A new email instruction has been sent to your email"
      	});
      }
    } catch ({ data: resendEmailError }) {
    	setResendNotice({
      	type: "error",
      	message: resendEmailError.errors
    	});
		}
	};
console.log(resendNotice);
	return (
		<div
			style={{
				height: height,
			}}
			className="bg-[#F7F8FA] flex justify-center items-center"
		>
			{ resendNotice.message.length !== 0 && (
        <AuthNotice 
        	type={resendNotice.type}
        	message={resendNotice.message}
        />
      )}
			<div className="bg-white w-[440px] px-[45px] py-[60px] rounded-[40px] border-1 border-[#EDEDED]">
				<img src={QuesteonLogo} alt="" className="h-[40px]" />
				<div className="grid justify-center py-[40px]">
					<img src={confirmationLogo} alt="" className="" />
				</div>
				<div className="grid justify-center pt-[20px]">
					<h1 className="text-[24px] leading-[120%] text-[#262626] text-center font-bold">
						{
							(tokenExpired || tokenInvalid) ? "Link Expired" : "Account Verified"
						}
					</h1>
					<p className="text-center text-[14px] leading-[21px] text-[#5A5A60]">
						{ data && data.message }
						{ error && error.data?.errors }
					</p>
					{ tokenExpired ? (
						<>
							<button
								onClick={() => {
									onSubmit()
								}}
								className="text-[16px] text-white font-bold leading-[24px] bg-[#2C7D92] rounded-[50px] py-[13px] px-[100px] mt-[30px]"
							>
								Request New Link
							</button>
							{ emailData?.email && (
								<>
									<p className="text-center text-[14px] text-[#5A5A60] leading-[21px] mt-[24px] mb-0">
										Your email :
									</p>
									<p className="text-center text-[14px] text-[#5A5A60] leading-[21px] font-bold mb-0">
										{emailData?.email}
									</p>
								</>
							)}
						</>
					) : (
						<Link
							to="/quests"
							className="text-[16px] text-white font-bold leading-[24px] bg-[#2C7D92] rounded-[50px] py-[13px] px-[100px] mt-[30px]"
						>
							Go to Dashboard
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default Confirmation;
