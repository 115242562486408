import classNames from "classnames";
import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { useGetPlaceTasksQuery } from "services/placeApi";
import { useGetSubplaceTasksQuery } from "services/subplaceApi";
import { TaskType } from "utils/questUtils";

const ListTask = () => {
	const { id, taskParentType, taskParentId, questId } = useParams();
	const { data: placeData } = useGetPlaceTasksQuery(taskParentId, {
		skip: taskParentType !== "places",
	});
	const { data: subplaceData } = useGetSubplaceTasksQuery(taskParentId, {
		skip: taskParentType !== "subplaces",
	});

	const { title, tasks } = placeData || subplaceData || {};
	return (
		<div className="user__ri">
			<div className="task__list">
				<h4>Tasks List</h4>
				<p>{title}</p>
				{tasks &&
					tasks.map((task, index) => (
						<div className="task__list--single--item" key={task.id}>
							<div
								className={classNames("task__number", {
									active: task.id === Number(id),
									"first-task": index === 0,
								})}
							>
								<span>{index + 1}</span>
							</div>
							<div className="task__list__ct">
								<Link
									to={`/quests/${questId}/${taskParentType}/${taskParentId}/tasks/${task.id}`}
								>
									<h4>{task.title}</h4>
								</Link>
								<p>
									<TaskType type={task.task_type} />
								</p>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default ListTask;
