import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { closeModal, showModal } from "features/modalSlice";
import { reFetchPlaces } from "features/questSlice";

export const useCloseModal = () => {
  const dispatch = useDispatch();
  const closeModalHandler = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);
  return closeModalHandler;
};

export const useReFetchPlaces = () => {
  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(reFetchPlaces());
  }, [dispatch]);
  return refetch;
};

export const useShowModal = () => {
  const dispatch = useDispatch();

  const showModalHandler = useCallback(
    ({ component, props }) => {
      if (component) {
        dispatch(showModal({ component, props }));
      }
    },
    [dispatch]
  );
  return showModalHandler;
};
