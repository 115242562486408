import React from "react";
import { useForm } from "react-hook-form";
import { useSignInMutation } from "services/authApi";
import Logo from "components/Logo";
import AuthNotice from "components/AuthNotice";
import LoginIcon from "assets/images/login.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function SignIn() {
  const { isAuthenticated, error: errorMessage } = useSelector(
    (state) => state.authentication
  );
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [signIn] = useSignInMutation();
  const onSubmit = async (dataSignIn) => {
    try {
      await signIn(dataSignIn).unwrap();
    } catch ({ data }) {
      setError(data?.errors);
      if (data?.data?.type === "EMAIL_NOT_VERIFIED") {
        navigate("/verify-email", { state: { email: dataSignIn.email } });
      }
    }
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [isAuthenticated, navigate, errorMessage]);

  return (
    <div className="singin-area">
      {error && <AuthNotice type="error" message={error} />}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="singin-box">
              <Logo />
              <div className="singin-title">
                <h3>✏️ Creator Dashboard</h3>
                <p>Enter your details to login to your account :</p>
              </div>
              <div className="singin-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="single-input">
                    <label>Email</label>
                    <input
                      type="email"
                      className={
                        errors.email
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Your email"
                      {...register("email", {
                        required: "Email is required.",
                      })}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                  <div className="single-input">
                    <label>Password</label>
                    <div className="single-input-eye">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="Your password"
                        {...register("password", {
                          required: "Password is required.",
                        })}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password.message}
                        </div>
                      )}
                      <a
                        href="#0"
                        className={`cc fa fa-fw field-icon toggle-password
                          ${ errors.password && "is-invalid"}
                        `}
                        onClick={handleShowPassword}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="single-check-fl">
                    <label className="containerss">
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark"></span>
                    </label>
                    <Link to="/forgot-password">Forgot password?</Link>
                  </div>
                  <div className="singin-btn">
                    <button type="submit">
                      <img src={LoginIcon} alt="" />
                      Sign In
                    </button>
                  </div>
                  <div className="singin-text">
                    <p>
                      Want to be a Creator? <Link to="/signup">Sign Up</Link>{" "}
                      here
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
