import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

import { useGetNotificationQuery } from "services/creatorApi";
import { FaPlus } from "react-icons/fa";
import RecentUserJoin from "components/quest/RecentUserJoin";
import { resetHeader } from "features/navigationSlice";
function Home() {
  const { data: notificationData } = useGetNotificationQuery();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const tabsActive = ["/", "/quests", "/quests/popular"].includes(pathname);

  useEffect(() => {
    dispatch(resetHeader());
  }, [dispatch]);
  return (
    <div className="main-content-body">
      <div className="dash-tab-create">
        <div className="dash-tab-left">
          <div className="dash-tab">
            <ul className="nav dash-tabs">
              <li className="dash-item nav-item">
                <NavLink
                  to="/quests/popular"
                  className={classNames("dash-link", { active: tabsActive })}
                >
                  Popular Quest
                </NavLink>
              </li>
              <li className="dash-item nav-item">
                <NavLink
                  to="/quests/list"
                  className={({ isActive }) =>
                    classNames("dash-link", { active: isActive })
                  }
                >
                  Quest List
                  {notificationData?.quests_count > 0 && (
                    <span> {notificationData?.quests_count}</span>
                  )}
                </NavLink>
              </li>
            </ul>

            <div className="dash-create-btn">
              <Link to="/quests/new">
                <FaPlus />&nbsp;
                Create Quest
              </Link>
            </div>
          </div>
          <div className="dash-tab-box-fl">
            <div className="tab-content">
              <div className="tab-pane fade show active">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <div className="dash-tab-right">
          <RecentUserJoin />
        </div>
      </div>
    </div>
  );
}

export default Home;
