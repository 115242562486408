import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setQuest } from "features/questSlice";
import { useForm, Controller } from "react-hook-form";
import RichTextInput from "components/inputs/RichTextInput";
import {
  useUpdateQuestMutation,
  useUpdateQuestStepMutation,
} from "services/questApi";
import { useNavigate } from "react-router-dom";

const ConclusionForm = () => {
  const dispatch = useDispatch();
  const { quest, currentNavigation } = useSelector((state) => state.quest);
  const { conclusion } = quest || {};
  const [updateQuest] = useUpdateQuestMutation();
  const [updateStep] = useUpdateQuestStepMutation();
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (conclusion) {
      const conclusionValue = {
        id: conclusion.id,
        description: conclusion.description,
      };
      reset(conclusionValue);
    }
  }, [conclusion, reset]);

  const onSubmit = async (data) => {
    const questData = {
      conclusion_attributes: data,
    };
    if (quest) {
      try {
        const id = quest.id;
        const questResponse = await updateQuest({
          id,
          quest: questData,
        }).unwrap();
        dispatch(setQuest(questResponse));
        if (currentNavigation === "place_and_task") {
          await updateStep({ id: id, step: "conclusion" });
        }
        navigate(`/quests/${quest?.slug}/edit/overview`);
      } catch ({ data: updateQuestError }) {
        updateQuestError?.data?.forEach(({ name, message }) => {
          setError(name, { type: "server", message });
        });
      }
    }
  };

  return (
    <div className="info-containt">
      <div className="info-title">
        <h3>
          <span>4</span> Conclusion
        </h3>
      </div>
      <div className="sinfo-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value={conclusion?.id} {...register(`id`)} />
          <div className="single-info">
            <label>
              Quest Conclusion <sup>*</sup>
            </label>
            <div className="single-input">
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RichTextInput
                    {...field}
                    placeholder="Tell us the conclusion about the quest"
                  />
                )}
              />
              {errors?.description && (
                <div className="invalid-feedback">
                  {errors?.description?.message}
                </div>
              )}
            </div>
          </div>

          <div className="form-single-btn">
            <Link
              to={`/quests/${quest?.slug}/edit/place`}
              className="priv btn-nav"
            >
              Previous
            </Link>
            <button type="submit" className="btn-nav">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConclusionForm;
