import React from "react";
import emptyReviewsIcon from "assets/images/icon-empty-reviews.svg";

const EmptyReviews = () => {
	return (
		<div className="dash-tab-containt py-7">
			<div className="dash-tab-img grid justify-center">
				<img src={emptyReviewsIcon} alt="" />
			</div>
			<div className="dash-tab-text mb-4">
				<h3>No review found</h3>
				<p>When people play your quest,</p>
        <p>they can give a feedback and review</p>
			</div>
		</div>
	);
};

export default EmptyReviews;
