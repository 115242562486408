import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import { useGetQuestStepQuery } from "services/questApi";
import { setCurrentNavigation } from "features/questSlice";

const QuestNavigation = () => {
	const dispatch = useDispatch();
	const { quest, currentAction } = useSelector((state) => state.quest);
	const { data } = useGetQuestStepQuery(quest?.id, { skip: !quest?.id });

	const steps = useMemo(() => {
		return ["quest_info", "intro", "place_and_task", "conclusion", "overview"];
	}, []);

	const currentStep = useMemo(() => {
		if (data) {
			return steps.findIndex((step) => step === data.step);
		}
		return -1;
	}, [data, steps]);

	const navigationActive = (navigationStep) => {
		const indexStep = steps.findIndex((step) => step === navigationStep);
		return currentStep + 1 >= indexStep;
	};

	const navigationChecked = (navigationStep) => {
		if (!data) {
			return false;
		}
		const indexStep = steps.findIndex((step) => step === navigationStep);
		return currentStep >= indexStep;
	};

	let basePath;
	switch (currentAction) {
		case "EDIT":
			basePath = `/quests/${quest?.slug}/edit`;
			break;
		default:
			basePath = "/quests/new";
	}
	const navigators = [
		{
			name: "Quest Info",
			active: true,
			path: basePath,
			checkMark: true,
			checked: navigationChecked("quest_info"),
		},
		{
			name: "Intro",
			active: navigationActive("intro"),
			path: `${basePath}/intro`,
			checkMark: true,
			checked: navigationChecked("intro"),
		},
		{
			name: "Places & Tasks",
			active: navigationActive("place_and_task"),
			path: `${basePath}/place`,
			checkMark: true,
			checked: navigationChecked("place_and_task"),
		},
		{
			name: "Conclusion",
			active: navigationActive("conclusion"),
			path: `${basePath}/conclusion`,
			checkMark: true,
			checked: navigationChecked("conclusion"),
		},
		{
			name: "Overview",
			active: navigationActive("overview"),
			path: `${basePath}/overview`,
			checkMark: false,
			checked: navigationChecked("overview"),
		},
	];

	useEffect(() => {
		if (data) {
			dispatch(setCurrentNavigation(data.step));
		}
	}, [dispatch, data]);
	return (
		<div className="info-check-list">
			<div className="info-check-list-title">
				<h3>Checklist</h3>
			</div>
			<div className="info-single-check">
				{navigators.map((navigation, index) => (
					<label className="containerk" key={`quest-navigation-${index}`}>
						<input type="checkbox" checked={navigation.checked} disabled />
						<p className="flex items-center">
							<Link
								className="link-dark"
								to={navigation.active ? navigation.path : "#0"}
							>
								<span className={classNames({ active: navigation.active })}>
									{index + 1}
								</span>{" "}
								{navigation.name}
							</Link>
						</p>
						{navigation.checkMark && <span className="checkmark"></span>}
					</label>
				))}
			</div>
		</div>
	);
};

export default QuestNavigation;
