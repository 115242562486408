import React from "react";
import { useParams } from "react-router-dom";
import Moment from "react-moment";

import { useGetListResentUserQuery } from "services/questApi";
import defaultUserImage from "assets/images/dash-user.png";

const RecentUser = () => {
	const { id } = useParams();
	const { data } = useGetListResentUserQuery(id);
	return (
		<div className="recent__user__join">
			<div className="recent__title">
				<div className="flex items-center text-[18px]">
					<img
						src={require("assets/images/icon/a5.png")}
						alt=""
						className="pr-2"
					/>
					Recent User Join
				</div>
			</div>
			{data?.map((user) => (
				<div className="join__single--blog" key={user.id}>
					<div className="lee--img circle-img">
						<img src={user.user_avatar_url || defaultUserImage} alt="" />
					</div>
					<div className="lle__content">
						<h5>{user.user_username}</h5>
						<p>joined this quest</p>
						<span>
							<Moment format="DD MMM . HH:mm">{user.created_at}</Moment>
						</span>
					</div>
				</div>
			))}
		</div>
	);
};

export default RecentUser;
