import React from "react";
import { Link } from "react-router-dom";

function QInfo({ title, subtitle, image, buttonRoute, buttonText }) {
  return (
    <div
      style={{
        minWidth: "100vh",
        minHeight: "100vh",
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <div>
        <img src={image} alt="" />
      </div>
      <div className="black-grey h3-bold">{title}</div>
      <div className="black-grey regular-14" style={{ marginTop: "8px" }}>
        {subtitle}
      </div>
      <div style={{ textAlign: "-webkit-center", marginTop: "32px" }}>
        <Link to={buttonRoute}>
          <button className="mt-32 btn-green">{buttonText}</button>
        </Link>
      </div>
    </div>
  );
}

export default QInfo;
