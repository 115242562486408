import { useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const useDeleteDialog = () => {
  const DeleteSwal = withReactContent(Swal);
  const confirmButtonColor = "#2C7D92";
  const deleteDialog = useCallback(
    async ({ deleteHandler, id }) => {
      const result = await DeleteSwal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.value) {
        try {
          const response = await deleteHandler(id).unwrap();
          if (response) {
            DeleteSwal.fire({
              title: "Deleted!",
              text: response.message,
              icon: "success",
              confirmButtonColor,
            });
          }
        } catch ({ errors }) {
          DeleteSwal.fire({
            title: "Oops..",
            icon: "error",
            text: errors,
            confirmButtonColor,
          });
        }
      }
    },
    [DeleteSwal, confirmButtonColor]
  );
  return deleteDialog;
};
