import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const quest = Cookies.get("quest");
const safeJsonParse = (data) => {
  try {
    return JSON.parse(data);
  } catch {
    return null;
  }
};
const initialState = {
  currentNavigation: "",
  currentAction: "NEW",
  navigation: {
    questInfo: true,
    intro: false,
    place: false,
    conclusion: false,
    overview: false,
  },
  quest: safeJsonParse(quest),
  places: [],
  intros: [],
  conclusion: {},
  place: {},
  reFetchPlaces: null,
};

const questSlice = createSlice({
  name: "quest",
  initialState,
  reducers: {
    setQuest: (state, { payload }) => {
      state.quest = payload;
      Cookies.set("quest", JSON.stringify(payload));
    },
    setConclusion: (state, { payload }) => {
      state.conclusion = payload;
    },

    setPlaces: (state, { payload }) => {
      state.places = payload;
    },
    setNavigation: (state, { payload }) => {
      state.navigation = payload;
    },
    setCurrentNavigation: (state, { payload }) => {
      state.currentNavigation = payload;
    },
    setNavigationQuestInfo: (state, { payload }) => {
      state.navigation.questInfo = payload;
    },
    setNavigationIntro: (state, { payload }) => {
      state.navigation.intro = payload;
    },
    setNavigationPlace: (state, { payload }) => {
      state.navigation.place = payload;
    },
    setNavigationConclusion: (state, { payload }) => {
      state.navigation.conclusion = payload;
    },
    setNavigationOverview: (state, { payload }) => {
      state.navigation.overview = payload;
    },
    resetNavigation: (state) => {
      state.currentNavigation = "";
      state.navigation = {
        questInfo: true,
        intro: false,
        place: false,
        conclusion: false,
        overview: false,
      };
    },
    setAction: (state, { payload }) => {
      state.currentAction = payload;
    },
    removeCurrentNavigation: (state) => {
      state.currentNavigation = "";
    },
    unSetQuest: (state) => {
      state.quest = null;
      state.places = null;
      Cookies.remove("quest");
    },
    reFetchPlaces: (state) => {
      state.reFetchPlaces = Math.random();
    },
    setPlace: (state, { payload }) => {
      state.place = payload;
    },
  },
});

export const {
  setQuest,
  unSetQuest,
  setAction,
  setCurrentNavigation,
  removeCurrentNavigation,
  setNavigation,
  setNavigationQuestInfo,
  setNavigationIntro,
  setNavigationPlace,
  setNavigationConclusion,
  setNavigationOverview,
  resetNavigation,
  setConclusion,
  reFetchPlaces,
  setPlace,
} = questSlice.actions;
export default questSlice.reducer;
