import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { baseApi } from "./services/baseApi";
import authenticationReducer from "./features/authenticationSlice";
import questReducer from "./features/questSlice";
import navigationReducer from "./features/navigationSlice";
import modalReducer from "./features/modalSlice";
import loadingReducer from "./features/loadingSlice";

const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    authentication: authenticationReducer,
    quest: questReducer,
    navigation: navigationReducer,
    modal: modalReducer,
    loading: loadingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

setupListeners(store.dispatch);
export default store;
