import React from "react";
import { useSelector } from "react-redux";

import HTMLView from "components/HTMLView";

const Info = () => {
  const { quest } = useSelector((state) => state.quest);
  return (
    <>
      {quest && (
        <>
          <div className="user--tab--contrent">
            <HTMLView content={quest.description} />
          </div>
          <div className="user--tab--check">
            <div className="check__single--item">
              <img src={require("assets/images/location.png")} alt="" />
              <p>Check Point</p>
              <h4>{quest.places_count}</h4>
            </div>
            <div className="check__single--item">
              <img
                src={require("assets/images/icon/clipboard-check-outline.png")}
                alt=""
              />
              <p>Task</p>
              <h4>{quest.tasks_count}</h4>
            </div>
            <div className="check__single--item">
              <img src={require("assets/images/icon/cc2.png")} alt="" />
              <p>Route</p>
              <h4>
                {quest.route} <sub>km</sub>
              </h4>
            </div>
          </div>
          <div className="reword__wrap">
            <h4>Reward</h4>
            <div className="reward__box">
              <div className="reward__img circle-img">
                <img src={quest.badge?.image?.url} alt="" />
              </div>
              <div className="reward__conntent">
                <h4>{quest.badge?.title}</h4>
              </div>
            </div>
          </div>
          <div className="Highlights__wrap">
            <h4>Highlights</h4>
            <div className="highlight__menu">
              <ul>
                {quest.highlights.map((highlight, index) => (
                  <li key={`highlight-${index}`}>{highlight}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="intro__wrap">
            <div className="intro__title">
              <h4>Intro</h4>
            </div>
            <div className="intro__fx">
              { quest.intro_image_url && (
                <div className="intro__img">
                  <img src={quest.intro_image_url} alt="" />
                </div>
              )}
              <div className="intro__content">
                <HTMLView content={quest.intro} />
                {quest.quest_intros.map((intro) => (
                  <div className="intro__single--blog" key={intro.id}>
                    <h5>{intro.title}</h5>
                    <HTMLView content={intro.description} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="comnc__box">
            <h4>Conclusion</h4>
            <HTMLView content={quest.conclusion?.description} />
          </div>
        </>
      )}
    </>
  );
};

export default Info;
