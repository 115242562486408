import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink, Outlet } from "react-router-dom";
import { FaPen, FaTrash, FaStar, FaCopy } from "react-icons/fa";
import { BsClock } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";
import Swal from "sweetalert2";
import RejectedIcon from "assets/images/icon-rejected.svg";
import NumberedListIcon from "assets/images/icon-numbered-list.svg";
import RadiusIcon from "assets/images/icon-radius.svg";
import TestQuestIcon from "assets/images/tst.png";
import CurrencyIcon from "assets/images/icon/CurrencyCircleDollar.png";
import HTMLView from "components/HTMLView";
import PublishFormModal from "components/quest/modalForm/PublishFormModal";
import {
	useGetQuestQuery,
	useGenerateTestLinkMutation,
	useUpdateQuestStatusMutation,
} from "services/questApi";
import { setQuest, unSetQuest } from "features/questSlice";
import { setAction } from "features/questSlice";
import { setHeader } from "features/navigationSlice";
import RecentUser from "./QuestDetail/RecentUser";
import QuestStatus from "pages/quest/QuestDetail/QuestStatus";

const QuestDetail = () => {
	const { id } = useParams();
	const { data } = useGetQuestQuery(id);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [generateTestLink] = useGenerateTestLinkMutation();
	const [updateQuestStatus] = useUpdateQuestStatusMutation();
	const [copied, setCopied] = useState(false);
	const [showPublishModal, setShowPublishModal] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	
	const onModalShow = () => {
		setShowPublishModal(true);
	};

	const handleOnChangeValue = () => {
		setIsChecked(!isChecked);
		console.log("isChecked");
	};

	useEffect(() => {
		dispatch(setHeader("QUEST"));
		dispatch(setQuest(data));
		dispatch(setAction("VIEW"));
	}, [data, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(unSetQuest());
		};
	}, [dispatch]);

	const onTestQuestClick = () => {
		generateTestLink(id);
	};

	const errorDialog = async (data) => {
		await Swal.fire({
			icon: "error",
			title: "Oops...",
			text: data.errors,
			confirmButtonColor: "#2C7D92",
		});
	};

	const onSubmitForReviewClick = async () => {
		const status = "submit_review";
		try {
			await updateQuestStatus({ id, status }).unwrap();
		} catch ({ data }) {
			errorDialog(data);
		}
	};

	const onPublishClick = async () => {
		const status = "published";
		try {
			await updateQuestStatus({ id, status }).unwrap();
      setShowPublishModal(false);
		} catch ({ data }) {
			errorDialog(data);
		}
	};

	let disableEditBtn = false;
	if(data) {
		disableEditBtn = ["review", "publish"].includes(data.status);
	};

	let disableSubmitForReviewBtn = false;
	if(data) {
		disableSubmitForReviewBtn = data?.step !== "conclusion";
	};

	let isFree = true;
	if(data){
		isFree = data?.price === 0;
	}

	return (
		<div>
			{data && (
				<>
					<div className="view__banner">
						<img src={data.image_url} alt="" />
					</div>
					<div className="botanic__area">
						<div className="flex items-center justify-between">
              <div className="flex items-center">
                <QuestStatus questStatus={data.status} />
							</div>

							<div className="botanic__button">
								{!data.deep_link && (
									<button className="btn" onClick={onTestQuestClick}>
										Test Quest
									</button>
								)}

								{["draft", "rejected"].includes(data.status) && (
									<div>
										<button
											className={classNames("h-12 px-6 m-2 rounded-full font-bold text-white", {
												"bg-gray-300": disableSubmitForReviewBtn,
												"bg-questeon": !disableSubmitForReviewBtn
											})}
											onClick={onSubmitForReviewClick}
											disabled={disableSubmitForReviewBtn}
										>
											Submit for Review
										</button>
									</div>
								)}

								{data.status === "ready" && (
									<button
										className="h-12 px-6 m-2 rounded-full text-white font-bold disabled bg-questeon"
										onClick={onModalShow}
									>
										Publish Now
									</button>
								)}

								{data.status === "review" && (
									<button
										className="h-12 px-6 m-2 rounded-full text-white font-bold bg-gray-300"
										disabled
									>
										Reviewing...
									</button>
								)}

								<button
									className={classNames(
										"font-bold flex justify-center items-center rounded-full h-12 px-6 m-2 border-1 border-solid text-questeon border-questeon", {
											"!text-[#C2C2C2] !border-[#C2C2C2]": disableEditBtn
									})}
									onClick={() => navigate(`/quests/${data?.slug}/edit`)}
									disabled={disableEditBtn}
								>
									<FaPen />
                  Edit
								</button>
								
								<button
                  className="bg-none"
                  disabled
                >
									<span>
										<FaTrash className="cursor-default text-[#C2C2C2]" />
									</span>
								</button>
							</div>
						</div>
						{
							<PublishFormModal
								showModal={showPublishModal}
								closeModal={() => setShowPublishModal(false)}
								valueChecked={isChecked}
								handleValue={handleOnChangeValue}
								publishClick={onPublishClick}
							/>
						}
						<div className="botanic__top mt-6">
							<div className="botanic__title">
								<h3>{data.title}</h3>
							</div>
						</div>
						<div className="botanic__star">
							<div className="single--star">
								<p>
									<FaStar style={{ color: "#FFCF50", marginRight: "4px" }} />
									&nbsp; {data.star}
								</p>
								<p>
									<img
										src={CurrencyIcon}
										alt=""
										className={classNames("", {
											"green": !isFree
										})}
									/>
									&nbsp; { isFree ? "Free" : data.price }
								</p>
								{ data.place_sequential && (
									<p>
										<img
											src={NumberedListIcon}
											alt=""
										/>
										&nbsp; Place Sequential
									</p>
								)}
								{ data.time_tracker && (
									<p>
										<BsClock />
										&nbsp; Time Tracker
									</p>
								)}
							</div>
						</div>
						<div className="user__wrap">
							<div className="user__fx">
								<div className="user__lf">
									{data.status === "rejected" && (
										<div className="rounded-[24px] bg-[#f15a5b]/10 p-[24px] mb-4">
											<div className="flex items-center">
												<div>
													<img
														src={RejectedIcon}
														alt=""
														className="w-[19.5px]"
													/>
												</div>
												<div className="pl-2 text-[16px] leading-[24px] text-[#F15A5B] font-bold">
													Quest Rejected
												</div>
											</div>
											<div className="mt-[16px]">
												<p className="text-[14px] leading-[21px] text-[#262626]">
													Your quest submission was rejected by our team, here
													are the reasons :
												</p>
												<div className="text-[14px] leading-[21px] text-[#262626] font-bold">
													<HTMLView content={data.reject_messages} />
												</div>
												<p className="text-[14px] leading-[21px] text-[#262626]">
													Please update your quest and try submit it again
													later. Thank you!
												</p>
											</div>
										</div>
									)}

									<div className="rounded-[24px] bg-[#ededed] p-[24px] mb-4 flex items-center">
										<img
											src={RadiusIcon}
											alt=""
											className="mr-3"
										/>
										<span className="text-[14px]">
											Radius detection will not be activated until the quest
											is <span className="font-bold">published</span>. Feel
											free to test the quest wherever you are, there's no
											restriction.
										</span>
									</div>

									{data.deep_link && (
										<div className="test__wrap bg-[#F7F8FA]/20">
											<div className="test__top mb-3 label-top" type="button">
												<div className="flex items-center text-[16px] font-semibold">
													<img
														src={TestQuestIcon}
														alt=""
														className="pr-2"
													/>
													Test Your Quest
												</div>
												<div className="label-icon"></div>
											</div>

											<div className="test__btm">
												<p>
													Test out your Quest with your friends or team
													first. When all is good, you can submit it to be
													reviewed by our team, then publish it online
													(available for everyone to join) when it's approved
													or ready. This link will stay active even after the
													quest is published.
												</p>
												<div className="test__bottom--imp">
													<input
														className="bg-[#EDEDED]"
														type="text"
														value={data.deep_link}
														disabled
													/>
													<CopyToClipboard
														text={data.deep_link}
														onCopy={() => setCopied(true)}
													>
														<button className="bg-[#2C7D92]">
															<FaCopy /> Copy link
														</button>
													</CopyToClipboard>
												</div>
												{copied && (
													<span className="text-success">Copied!</span>
												)}
											</div>
										</div>
									)}

									<div className="view-tabs nav">
										<div className="view-item nav-item">
											<NavLink
												to={`/quests/${data?.slug}/`}
												className={({ isActive }) =>
													classNames("view-link nav-link", { active: isActive })
												}
											>
												Quest Info
											</NavLink>
										</div>
										<div className="view-item nav-item">
											<NavLink
												to={`/quests/${data?.slug}/places`}
												className={({ isActive }) =>
													classNames("view-link nav-link", { active: isActive })
												}
											>
												Places
											</NavLink>
										</div>
										<div className="view-item nav-item">
											<NavLink
												to={`/quests/${data?.slug}/users`}
												className={({ isActive }) =>
													classNames("view-link nav-link", { active: isActive })
												}
											>
												Users
											</NavLink>
										</div>
										<div className="view-item nav-item">
											<NavLink
												to={`/quests/${data?.slug}/reviews`}
												className={({ isActive }) =>
													classNames("view-link nav-link", { active: isActive })
												}
											>
												Reviews
											</NavLink>
										</div>
									</div>
									<div className="tab-content">
										<div className="fade tab-pane active show">
											<Outlet />
										</div>
									</div>
								</div>
								<div className="user__ri">
									<RecentUser />
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default QuestDetail;
