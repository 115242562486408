import React from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useRegistrationMutation } from "services/creatorApi";
import Logo from "components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

function SignUp() {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm({ mode: "onChange" });
	const navigate = useNavigate();
	const [registration] = useRegistrationMutation();
	const [showPassword, setShowPassword] = useState(false);
	const [signup, setSignup] = useState(false);
	const handleSignup = () => {
		setSignup(!signup);
	};
	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const onSubmit = async (signUpData) => {
		try {
			const result = await registration({ creator: signUpData }).unwrap();
			if (result) {
				navigate("/verify-email", { state: { email: signUpData.email } });
			}
		} catch ({ data: singUpError }) {
			singUpError?.data?.forEach(({ name, message }) => {
				setError(name, { type: "server", message });
			});
		}
	};
	return (
		<div className="singin-area">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="singin-box">
							<Logo />
							<div className="singin-title">
								<h3>Become a Creator</h3>
								<p>Enter your details below to become a creator</p>
							</div>
							<div className="singin-form">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="single-input">
										<label>
											Name <sup>*</sup>
										</label>
										<input
											type="text"
											className={classNames("form-control", {
												"is-invalid": errors.name
											})}
											placeholder="Your name"
											{...register("name", {
												required: "Name is required.",
											})}
										/>
										{errors.name && (
											<div className="invalid-feedback">
												{errors.name.message}
											</div>
										)}
									</div>
									<div className="single-input">
										<label>
											Email <sup>*</sup>
										</label>
										<input
											type="email"
											className={classNames("form-control", {
												"is-invalid": errors.email
											})}
											placeholder="Your email"
											{...register("email", {
												required: "Email is required.",
											})}
										/>
										{errors.email && (
											<div className="invalid-feedback">
												{errors.email.message}
											</div>
										)}
									</div>
									<div className="single-input">
										<label>
											Password <sup>*</sup>
										</label>
										<div className="single-input-eye">
											<input
												type={showPassword ? "text" : "password"}
												className={`form-control ${
													errors.password && "is-invalid"
												}`}
												placeholder="Your password"
												{...register("password", {
													required: "Password is required.",
												})}
											/>
											{errors.password && (
												<div className="invalid-feedback">
													{errors.password.message}
												</div>
											)}
											<a
												href="#0"
												className={classNames("cc fa fa-fw field-icon toggle-password", {
													"is-invalid": errors.password
												})}
												onClick={handleShowPassword}
											>
												<FontAwesomeIcon
													icon={showPassword ? faEyeSlash : faEye}
												/>
											</a>
										</div>
									</div>
									<div className="flex items-center leading-[21px] text-[#262626] mb-[20px]">
										<div onClick={handleSignup}>
											<div
												className={classNames(
													"flex items-center justify-center border-1 border-[#2C7D92] w-[16px] h-[16px] bg-[#2C7D92] cursor-pointer rounded-sm",
													{
														"!border-[#EDEDED] !bg-[#EDEDED] rounded-sm":
															!signup,
													}
												)}
											>
												{signup && (
													<FaCheck className="text-white w-[10px] h-[10px]" />
												)}
											</div>
										</div>
										<div className="pl-2 text-[12px] leading-[16px]">
											I agree to the{" "}
											<Link to="/terms-and-conditions" target="_blank">
												terms & conditions
											</Link>
										</div>
									</div>
									<div className="singin-btn">
										<button
											className={classNames(
												"flex items-center justify-center text-[#FFFFFF] text-[16px] leading-[24px] border-[#C2C2C2] bg-[#2C7D92] font-bold py-[13px] px-[145px] rounded-[50px]",
												{
													"!bg-[#C2C2C2]": !signup,
												}
											)}
											disabled={!signup}
										>
											Sign&nbsp;Up
										</button>
									</div>
									<div className="singin-text">
										<p>
											Are you an existing Creator?{" "}
											<Link to="/signin">Sign in</Link> here
										</p>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SignUp;
