import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { removeAuthentication } from "features/authenticationSlice";
import { setLoading } from "features/loadingSlice";

export const withPagination = (meta) => {
  const { headers } = meta?.response;
  return {
    total: Number(headers?.get("Total")),
    perPage: Number(headers?.get("Per-Page")),
    page: Number(headers?.get("X-Page")),
  };
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const {
      authentication: { accessToken },
    } = getState();

    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  if (api.type === "mutation") {
    api.dispatch(setLoading());
  }
  let result = await baseQuery(args, api, extraOptions);

  if (result && api.type === "mutation") {
    api.dispatch(setLoading());
  }

  if (result.error && result.error.status === 401) {
    const errorMessage = { error: result.error.data?.errors };
    api.dispatch(removeAuthentication(errorMessage));
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Places", "Creator"],
  endpoints: () => ({}),
});
