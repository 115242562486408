import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BounceLoader from "react-spinners/BounceLoader";

import React from "react";
import classNames from "classnames";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Modal from "./Modal";
import { setInActive } from "features/navigationSlice";

const ProtectedRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.authentication);
  const location = useLocation();
  return isAuthenticated ? (
    <DefaultLayout />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const { active: navigationActive } = useSelector((state) => state.navigation);
  const { currentAction } = useSelector((state) => state.quest);
  const { isLoading } = useSelector((state) => state.loading);

  const onOverlayClick = () => {
    if (!isLoading) {
      dispatch(setInActive());
    }
  };

  return (
    <>
      <div
        className={classNames("overlay", {
          active: navigationActive || isLoading,
          isLoading,
        })}
        onClick={onOverlayClick}
      >
        <div className="loading">
          <BounceLoader color={"#2FAE55"} loading={isLoading} />
        </div>
      </div>
      <div className="dashbord-area">
        <div className="dashboard-container">
          <Sidebar />
          <div
            className={classNames(
              {
                "view__page--body": currentAction === "VIEW",
              },
              "main-content"
            )}
          >
            <Header />
            <Outlet />
          </div>
        </div>
      </div>
      <Modal />
    </>
  );
};

export default ProtectedRoutes;
