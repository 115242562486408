export const TaskTypeOptions = [
	{ label: "Short Answer", value: "short_answer" },
	{ label: "Information", value: "information" },
	{ label: "Action", value: "action" },
	{ label: "Polling", value: "polling" },
	{ label: "Scan QR", value: "scan_qr" },
	{ label: "Check-In", value: "check_in" },
	{ label: "Take Photo", value: "photo" },
	{ label: "Multiple Choice", value: "multiple_choice" },
	{ label: "Spot It", value: "guess_the_difference" },
	{ label: "Augmented Reality", value: "augmented_reality" },
];

export const QuestStatusOptions = [
	{
		label: "Draft",
		value: "draft",
	},
	{
		label: "Publish",
		value: "publish",
	},
	{
		label: "On Review",
		value: "review",
	},
	{
		label: "Ready",
		value: "ready",
	},
	{
		label: "Rejected",
		value: "rejected",
	},
];

export const TaskType = ({ type }) => {
	const { label: taskType } = TaskTypeOptions.find(
		(taskType) => taskType.value === type
	) || { label: type };
	return taskType;
};

export const QuestStatus = ({ status }) => {
	const { label: questStatus } = QuestStatusOptions.find(
		(questStatus) => questStatus.value === status
	) || { label: status };
	let statusClassName = "";
	switch (status) {
		case "publish":
			statusClassName =
				"text-[12px] leading-[16px] text-[#2FAE55] border-1 border-[#2FAE55] bg-[#2fae55]/10";
			break;
		case "draft":
			statusClassName =
				"text-[12px] leading-[16px] text-[#262626] border-1 border-[#7D7D7D] bg-[#262626]/10";
			break;
		case "rejected":
			statusClassName =
				"text-[12px] leading-[16px] text-[#F15A5B] border-1 border-[#F15A5B] bg-[#f15a5b]/10 ";
			break;
		case "ready":
			statusClassName =
				"text-[12px] leading-[16px] text-[#394DA0] border-1 border-[#394DA0] bg-[#394da0]/10 ";
			break;
		case "review":
			statusClassName =
				"text-[12px] leading-[16px] text-[#7D7D7D] border-1 border-[#C2C2C2] bg-[#EDEDED] ";
			break;
		default:
			break;
	}
	return (
		<span className={`ta-btn status ${statusClassName}`}>{questStatus}</span>
	);
};
