import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useGetQuestQuery } from "services/questApi";
import { setQuest, unSetQuest, setAction } from "features/questSlice";
import { setHeader } from "features/navigationSlice";
import QuestNavigation from "components/quest/QuestNavigation";

const QuestEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useGetQuestQuery(id);

  useEffect(() => {
    dispatch(setHeader("QUEST"));
    if (data) {
      dispatch(setQuest(data));
      dispatch(setAction("EDIT"));
    }
  }, [dispatch, data]);

  useEffect(() => {
    return () => {
      dispatch(unSetQuest());
    };
  }, [dispatch]);

  return (
    <div className="main-content-body">
      <div className="info-area">
        <Outlet />
        <QuestNavigation />
      </div>
    </div>
  );
};

export default QuestEdit;
