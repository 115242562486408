import React from "react";
import StatusLabel from "pages/quest/QuestDetail/QuestStatus/StatusLabel";
import StatusDesc from "pages/quest/QuestDetail/QuestStatus/StatusDesc";

const QuestStatus = ({ questStatus }) => {
  return (
    <>
      <StatusLabel questStatus={questStatus}/>

			<div className="text-[14px] leading-[21px] text-[#5A5A60] ml-2">
				<StatusDesc questStatus={questStatus}/>
			</div>
		</>
  );
}

export default QuestStatus;
