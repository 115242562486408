import { baseApi } from "./baseApi";

export const creatorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    registration: builder.mutation({
      query: (data) => ({
        url: "/api/v1/creator/registrations",
        method: "POST",
        body: data,
      }),
    }),
    getCreator: builder.query({
      query: () => "/api/v1/creator/me",
      providesTags: ["Creator"],
    }),
    getNotification: builder.query({
      query: () => "/api/v1/creator/me/notification",
      providesTags: ["Notification"],
    }),
    updateCreator: builder.mutation({
      query: (data) => ({
        url: "/api/v1/creator/me",
        method: "PUT",
        body: data,
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedCreator } = await queryFulfilled;
          dispatch(
            creatorApi.util.updateQueryData(
              "getCreator",
              undefined,
              (draft) => {
                Object.assign(draft, updatedCreator);
              }
            )
          );
        } catch {
          dispatch(creatorApi.util.invalidateTags("Creator"));
        }
      },
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: "/api/v1/creator/me/change_password",
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const {
  useRegistrationMutation,
  useGetCreatorQuery,
  useUpdateCreatorMutation,
  useGetNotificationQuery,
  useChangePasswordMutation,
} = creatorApi;
