import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { FaTrash } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import FileIcon from "assets/images/file-icon.png";
import ARFileIcon from "assets/images/icon-ar.svg";

const ImageInput = React.forwardRef((props, ref) => {
	const fileRef = useRef();
	const [image, setImage] = useState(null);
	const [files, setFiles] = useState(null);
	const { onChange, onError, name, value, error, imageType } = props;

	const onBrowseClick = (e) => {
		fileRef.current.click();
		e.preventDefault();
	};
	useEffect(() => {
		if (value && value?.constructor === String && value !== "SERVER_FILE") {
			setImage(value);
			onChange("SERVER_FILE");
		}
		if (files && !value) {
			onChange(files);
		}
	}, [value, onChange, files]);

	const handleFile = (files) => {
		let imageError = false;

		const validTypes = () => {
			switch (imageType) {
				case "augmentedReality":
					return ["image/png"];
				default:
					return ["image/jpeg", "image/jpg", "image/png"];
			}
		};

		if (files) {
			const file = files[0];
			const imageUrl = URL.createObjectURL(file);
			const inputTypes = validTypes();

			if (onError) {
				if (file.size / 1024 > 5120) {
					imageError = true;
					onError(name, { type: "file_size", message: "File size is too big" });
				}
				if (inputTypes.indexOf(file.type) === -1) {
					imageError = true;
					onError(name, { type: "file_type", message: "Invalid file type" });
				}
			}
			if (!imageError) {
				setImage(imageUrl);
				setFiles(files);
				onChange(files);
			}
		}
	};

	const onImageRemove = () => {
		setImage(null);
		onChange("");
	};

	const onFileInputChange = (event) => {
		if (event.target.files && event.target.files.length > 0) {
			handleFile(event.target.files);
		}
	};

	const onFileDrop = (event) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files?.length > 0) {
			handleFile(files);
		}
	};

	const dragOver = (e) => {
		e.preventDefault();
	};

	const dragEnter = (e) => {
		e.preventDefault();
	};

	const dragLeave = (e) => {
		e.preventDefault();
	};

	const inputBox = (imageType) => {
		switch (imageType) {
			case "augmentedReality":
				return (
					<div>
						<div className={classNames("info-dorp-img drop-zone__prompt")}>
							<div className="flex justify-center">
								<img src={ARFileIcon} alt="" />
							</div>
						</div>
						<div className="info-dorp-text">
							<p className="drop-zone__prompt">
								Drop image here or{" "}
								<a href="#0" onClick={onBrowseClick}>
									browse
								</a>
							</p>
							<span className="drop-zone__prompt">
								Must be a PNG file with a transparent background up to 5mb
							</span>
						</div>
					</div>
				);
			default:
				return (
					<div>
						<div className={classNames("info-dorp-img drop-zone__prompt")}>
							<div className="flex justify-center">
								<img src={FileIcon} alt="" />
							</div>
						</div>
						<div className="info-dorp-text">
							<p className="drop-zone__prompt">
								Drop your image here or{" "}
								<a href="#0" onClick={onBrowseClick}>
									browse
								</a>
							</p>
							<span className="drop-zone__prompt">
								Support JPG, JPEG & PNG up to 5mb
							</span>
						</div>
					</div>
				);
		}
	};

	return (
		<div
			className={classNames("info-drop-box drop-zone image-input", {
				"invalid-drop-zone": error,
			})}
			style={
				image && {
					backgroundImage: `url(${image})`,
					backgroundSize: "cover",
				}
			}
			onDragOver={dragOver}
			onDragEnter={dragEnter}
			onDragLeave={dragLeave}
			onDrop={onFileDrop}
		>
			<input
				type="file"
				className="drop-zone__input"
				ref={(e) => {
					ref(e);
					fileRef.current = e;
				}}
				onChange={onFileInputChange}
			/>
			{image && (
				<div className="overlay-image">
					<div className="overlay-image-detail fadeIn-top">
						<button
							onClick={onBrowseClick}
							className="btn btn-default overlay-image-btn-edit"
						>
							<div className="flex items-center">
								<RiEdit2Fill style={{ marginRight: "5px" }} />
								Edit
							</div>
						</button>
						<button
							onClick={onImageRemove}
							className="btn btn-default overlay-image-btn-del"
						>
							<FaTrash />
						</button>
					</div>
				</div>
			)}

			{!image && inputBox(imageType)}
		</div>
	);
});

export default ImageInput;
