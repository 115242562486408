import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "assets/images/icon/Close-Square.png";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setQuest } from "features/questSlice";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  useUpdateQuestMutation,
  useUpdateQuestStepMutation,
} from "services/questApi";
import { serializeData } from "utils/serializeData";
import ImageInput from "components/inputs/ImageInput";
import RichTextInput from "components/inputs/RichTextInput";
import classNames from "classnames";

const IntroForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateQuest] = useUpdateQuestMutation();
  const [updateStep] = useUpdateQuestStepMutation();
  const [deletedIntros, setDeletedIntros] = useState([]);
  const { quest, currentNavigation } = useSelector((state) => state.quest);

  const emptyIntrosAttributes = {
    title: "",
    description: "",
  };

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    name: "quest_intros_attributes",
    control,
  });

  const onSubmit = async (data) => {
    const dataWithDeletedIntros = {
      ...data,
      quest_intros_attributes: [
        ...data.quest_intros_attributes,
        ...deletedIntros,
      ],
    };

    const questData = serializeData({ quest: dataWithDeletedIntros });
    if (quest) {
      try {
        const id = quest.id;
        const questResponse = await updateQuest({
          id,
          quest: questData,
        }).unwrap();
        dispatch(setQuest(questResponse));
        if (currentNavigation === "quest_info") {
          await updateStep({ id: id, step: "intro" });
        }
        navigate(`/quests/${quest?.slug}/edit/place`);
      } catch ({ data: updateQuestError }) {
        updateQuestError?.data?.forEach(({ name, message }) => {
          setError(name, { type: "server", message });
        });
      }
    }
  };

  const onRemoveIntroClick = (index) => {
    const intro = getValues(`quest_intros_attributes[${index}]`);
    if (intro && intro.id) {
      setDeletedIntros([...deletedIntros, { id: intro.id, _destroy: true }]);
    }
    remove(index);
  };

  useEffect(() => {
    if (quest) {
      const questIntros = quest.quest_intros;

      const questIntrosAttributes =
        questIntros.length !== 0
          ? questIntros?.map((intro) => ({
              id: intro.id,
              title: intro.title,
              description: intro.description,
            }))
          : [
              {
                title: "",
                description: "",
              },
            ];

      const introValue = {
        intro: quest.intro,
        intro_image: quest.intro_image_url,
        quest_intros_attributes: questIntrosAttributes,
      };
      reset(introValue);
    }
  }, [reset, quest]);

  return (
    <div className="info-containt">
      <div className="info-title">
        <h3>
          <span>2</span> Intro
        </h3>
      </div>
      <div className="sinfo-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="single-info">
            <label>
              Intro Banner <sup>*</sup>
            </label>
            <div>
              <Controller
                name="intro_image"
                control={control}
                defaultValue=""
                rules={{ required: "Intro Banner is required" }}
                render={({ field }) => (
                  <ImageInput
                    {...field}
                    onError={setError}
                    error={errors.intro_image}
                  />
                )}
              />
              {errors?.intro_image && (
                <div className="text-danger">
                  {errors?.intro_image?.message}
                </div>
              )}
            </div>
          </div>
          <div className="single-info">
            <label>
              Intro Description <sup>*</sup>
            </label>
            <div className="single-input">
              <Controller
                name="intro"
                control={control}
                render={({ field }) => (
                  <RichTextInput
                    {...field}
                    placeholder="Add intro description"
                  />
                )}
              />
            </div>
          </div>
          <div className="single-info">
            <label>Notes</label>
            <div className="single-blocks">
              {fields.map((item, index) => (
                <div className="single-block" key={item.id}>
                  <div className="block-title">
                    <h3>Notes #{index + 1}</h3>
                    <div className="close-block">
                      {index !== 0 && (
                        <a
                          href="#0"
                          onClick={() => {
                            onRemoveIntroClick(index);
                          }}
                        >
                          <img src={DeleteIcon} alt="" />
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="blok-input">
                    {Number.isInteger(item.id) && (
                      <input
                        type="hidden"
                        value={item.id}
                        {...register(`quest_intros_attributes[${index}].id`)}
                      />
                    )}
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid":
                          errors?.["quest_intros_attributes"]?.[index]?.[
                            "title"
                          ],
                      })}
                      placeholder="Block title, e.g. : Tips, Facts, etc."
                      defaultValue={item.title}
                      {...register(`quest_intros_attributes[${index}].title`, {
                        required: "Title is required",
                      })}
                    />
                    {errors?.["quest_intros_attributes"]?.[index]?.[
                      "title"
                    ] && (
                      <div className="invalid-feedback">
                        {
                          errors?.["quest_intros_attributes"]?.[index]?.[
                            "title"
                          ].message
                        }
                      </div>
                    )}
                  </div>
                  <div className="blok-input">
                    <Controller
                      name={`quest_intros_attributes[${index}].description`}
                      control={control}
                      defaultValue={item.description}
                      render={({ field }) => (
                        <RichTextInput
                          {...field}
                          placeholder="Block description"
                        />
                      )}
                    />
                    {errors?.["quest_intros_attributes"]?.[index]?.[
                      "description"
                    ] && (
                      <div className="invalid-feedback">
                        {
                          errors?.["quest_intros_attributes"]?.[index]?.[
                            "description"
                          ].message
                        }
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div className="single-input-add">
                {fields.length < 2 && (
                  <>
                    <a
                      href="#0"
                      onClick={() => {
                        append(emptyIntrosAttributes);
                      }}
                      className=""
                    >
                      <FaPlus />
                      &nbsp; Add another note
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="form-single-btn">
            <Link to={`/quests/${quest?.slug}/edit`} className="priv btn-nav">
              Previous
            </Link>
            <button type="submit" className="btn-nav">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IntroForm;
