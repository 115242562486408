import React from "react";
import { FaStar } from "react-icons/fa";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useGetReviewChartQuery } from "services/questApi";

const ReviewChart = () => {
	const { quest } = useSelector((state) => state.quest);
	const { data } = useGetReviewChartQuery(quest?.id, { skip: !quest });
	return (
		<div className="review__progress">
			{data?.map((review) => (
				<ReviewStar data={review} key={`star-${review.star}`} />
			))}
		</div>
	);
};

const ReviewStar = ({ data }) => {
	return (
		<div className="progress--fst">
			<span className="flex">
				{data.star}&nbsp;
				<FaStar />
			</span>
			<Progress className="prg" percentage={data.percentage} />
			<p>{data.total}</p>
		</div>
	);
};

const Progress = styled.div`
	&:before {
		width: ${({ percentage }) => percentage};
	}
`;
export default ReviewChart;
