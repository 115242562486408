import React from "react";
import WarningCircle from "assets/images/icon-warning-circle.svg";
import CheckCircle from "assets/images/icon-check-circle.svg";
import classNames from "classnames";

const AuthNotice = (props) => {
	let iconImage = WarningCircle;
	if(props?.type === "success") {
		iconImage = CheckCircle;
	}
	
	return (
		<div className={classNames("notice", {
			"success": props?.type === "success" 
		})}>
			<p className="flex items-center">
				<img src={iconImage} alt="" className="pr-2" /> {props?.message}
			</p>
		</div>
	);
};

export default AuthNotice;
