import React from "react";
import timerIcon from "assets/images/timer.png";
import starIcon from "assets/images/icon/Star.png";
import questIcon from "assets/images/d-h2.png";
import { usePopularQuestQuery } from "services/questApi";
import { useGetNotificationQuery } from "services/creatorApi";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import EmptyQuest from "./EmptyQuest";

const PopularQuest = () => {
	const { data: popularQuestData, isLoading } = usePopularQuestQuery();
	const { data: notificationData } = useGetNotificationQuery();
	const popularQuestDataEmpty = popularQuestData?.length === 0;
	const questEmpty = notificationData?.quests_count === 0;
	const navigate = useNavigate();
	const onQuestClick = (quest) => {
		navigate(`/quests/${quest.slug}`);
	};
	return (
		<>
			{isLoading && <SkeletonQuest count={5} />}
			{popularQuestDataEmpty && questEmpty && <EmptyQuest />}
			{popularQuestDataEmpty && !questEmpty && <EmptyPopularQuest />}
			{!popularQuestDataEmpty && (
				<div className="dash-popular-area">
					{popularQuestData?.map((quest) => (
						<div
							className="single-popular"
							onClick={() => {
								onQuestClick(quest);
							}}
							key={quest.id}
							style={{ cursor: "pointer" }}
						>
							<div className="popular-img">
								<img src={quest.image_url} alt="" />
							</div>
							<div className="popular-text text-sm">
								<h3>{quest.title}</h3>
								<div href="#0" className="flex py-2">
									<img src={questIcon} alt="" className="pr-2 h-5" />
									{quest.users_count} Users
								</div>
								<div href="#0" className="flex">
									<img src={starIcon} alt="" className="pr-2 h-5" />
									<span className="fw-bold">{quest.star}</span>&nbsp;(
									{quest.reviews_count} Reviews)
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

const EmptyPopularQuest = () => {
	return (
		<div className="dash-tab-containt">
			<div className="dash-tab-img grid justify-center">
				<img src={timerIcon} alt="" />
			</div>
			<div className="dash-tab-text">
				<h3>We still calculating...</h3>
				<p>Your quest need more reviews & users to join</p>
			</div>
		</div>
	);
};

const SkeletonQuest = ({ count = 1 }) => {
	return (
		<div className="dash-popular-area">
			{Array.from({ length: count }).map((_, index) => (
				<div className="single-popular" key={`popular-${index}`}>
					<div className="popular-img">
						<Skeleton height="200px" />
					</div>
					<div className="popular-text">
						<h3>
							<Skeleton width={200} />
						</h3>
						<a href="#0">
							<img src={questIcon} alt="" />
							<Skeleton width={200} />
						</a>
						<a href="#0">
							<img src={starIcon} alt="" />
							<Skeleton width={200} />
						</a>
					</div>
				</div>
			))}
		</div>
	);
};

export default PopularQuest;
