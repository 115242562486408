import classNames from "classnames";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { FaCheck, FaCircle } from "react-icons/fa";

const PublishFormModal = ({
	showModal,
	closeModal,
	valueChecked,
	handleValue,
	publishClick,
}) => {
	return (
		<div className="">
			<Modal
				show={showModal}
				onHide={closeModal}
				dialogClassName="modal-10w"
				aria-labelledby="example-custom-modal-styling-title"
				style={{
					width: "400px",
					top: "50%",
					left: "50%",
					right: "auto",
					bottom: "auto",
					marginRight: "-50%",
					transform: "translate(-50%, -50%)",
				}}
			>
				<Modal.Header
					className="border-bottom-0"
					closeButton={closeModal}
				></Modal.Header>
				<Modal.Body className="">
					<div className="px-[24px] mb-10">
						<div className="text-[24px] leading-[120%] font-bold text-[#262626]">
							You will publish the quest
						</div>
						<div className="text-[14px] leading-[21px] text-[#262626] pt-[32px]">
							Please note, currently after you publish the quest :
							<ul className="pt-[16px]">
								<li className="pb-[11px] flex items-center">
									<div className="text-[#FFCF50] w-[5px] mr-2">
										<FaCircle className="text-[#FFCF50] w-[5px]" />
									</div>
									You can't take down the quest.
								</li>
								<li className="pb-[11px] flex items-center">
									<div className="text-[#FFCF50] w-[5px] mr-2">
										<FaCircle className="text-[#FFCF50] w-[5px]" />
									</div>
									You can't edit the quest anymore.
								</li>
								<li className="pb-[11px] flex items-start">
									<div className="text-[#FFCF50] w-[5px] mr-2 pt-1">
										<FaCircle className="text-[#FFCF50] w-[5px]" />
									</div>
									<div>
										You also can't edit the order of the places
										or tasks.
									</div>
								</li>
								<li className="pb-[11px] flex items-start">
									<div className="text-[#FFCF50] w-[5px] mr-2 pt-1">
										<FaCircle className="text-[#FFCF50] w-[5px]" />
									</div>
									Radius detection will be activated and the quest can only be
									played within a set radius.
								</li>
							</ul>
						</div>
						<div className="flex items-center text-[14px] leading-[21px] text-[#262626] pt-[32px] mb-[32px]">
							<div onClick={handleValue}>
								<div
									className={classNames("flex items-center justify-center border-1 border-[#2C7D92] w-[20px] h-[20px] bg-[#2C7D92] cursor-pointer", {
										"!border-[#EDEDED] !bg-[#EDEDED]": !valueChecked
									})}
								>
									{ valueChecked && (
										<FaCheck className="text-white" />
									)}
								</div>
							</div>
							<div className="pl-2">
								I agree and wish to continue
							</div>
						</div>
						<div>
							<button
								className={classNames("flex items-center justify-center text-[#FFFFFF] text-[16px] leading-[24px] border-[#C2C2C2] bg-[#2C7D92] font-bold py-[13px] px-[145px] rounded-[50px]", {
									"!bg-[#C2C2C2]": !valueChecked
								})}
								onClick={publishClick}
								disabled={!valueChecked}
							>
								Publish
							</button>
							<button
								className="flex items-center justify-center text-[#F15A5B] text-[16px] leading-[24px] border-[#F15A5B] border-1 font-bold py-[13px] px-[145px] rounded-[50px] mt-3"
								onClick={closeModal}
							>
								Cancel
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default PublishFormModal;
