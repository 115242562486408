import React from "react";
import emptyTableIcon from "assets/images/table1.png";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const EmptyQuest = () => {
	return (
		<div className="dash-tab-containt">
			<div className="dash-tab-img grid justify-center">
				<img src={emptyTableIcon} alt="" />
			</div>
			<div className="dash-tab-text">
				<h3>It’s quite empty in here 👀</h3>
				<p>No quest has been made. Let’s create one</p>
			</div>
			<div className="dash-tab-btn">
				<Link to="/quests/new">
        	<FaPlus />&nbsp;
          Create First Quest
        </Link>
			</div>
		</div>
	);
};

export default EmptyQuest;
