import React from "react";
import { useSelector } from "react-redux";
import { FaStar } from "react-icons/fa";
import Moment from "react-moment";
import classNames from "classnames";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetReviewsQuery } from "services/questApi";
import Pagination from "./Pagination";
import ReviewChart from "./ReviewChart";
import arrow_status from "../../../assets/images/arrow_status.svg";
import EmptyReviews from "pages/quest/QuestDetail/EmptyStates/EmptyReviews";

const Review = () => {
	const { quest } = useSelector((state) => state.quest);
	const { id } = useParams();
	const [searchParams] = useSearchParams();
	const pageQuery = searchParams.get("page");
	
	const { data, isLoading } = useGetReviewsQuery({
		id,
		page: pageQuery,
	});
	
	const { reviews, total, perPage, page: currentPage } = data || {};
	const emptyReviews = reviews?.length === 0;

	return (
		<>
			{ emptyReviews && <EmptyReviews /> }
			{ !emptyReviews && quest && (
				<div className="review__wrap">
					<div className="review__top">
						<div className="review__star--top--fx">
							<div className="review__rating">
								<h4>
									<span>
										<FaStar />
									</span>
									{quest.star}
								</h4>
								<p>Based on {quest.reviews_count} reviews</p>
							</div>
							<ReviewChart />
						</div>
					</div>
					<div className="say__wrap">
						<div className="say__top">
							<h4>️💬 What they say</h4>
							<div className="flex border-1 border-[#2C7D92] rounded-[24px] py-2 px-3">
								Sort :&nbsp;
								<select className="after:bg-white cursor-pointer">
									<option value="newest" className="after:bg-white">
										Newest First
									</option>
									<option value="lowest" className="after:bg-white">
										Oldest First
									</option>
									<option value="highest" className="after:bg-white">
										Highest First
									</option>
									<option value="lowest" className="after:bg-white">
										Lowest First
									</option>
								</select>
								&nbsp;
								<img src={arrow_status} alt="" />
							</div>
						</div>
						{reviews?.map((review, index) => (
							<div
								className={classNames("say__single--box", {
									say__last: index + 1 === reviews.length,
								})}
								key={review.id}
							>
								<div className="say__fx">
									<div className="say__left">
										<div className="say_lf-top">
											<div className="say__img circle-img">
												<img src={review.user?.avatar_url} alt="" />
											</div>
											<div className="say__ct">
												<h5>{review.user?.username}</h5>
												<div className="say__star flex">
													{Array.from({ length: 5 }).map((_, index) => (
														<span key={`star-${review.id}-${index}`}>
															<FaStar
																className={classNames("", {
																	"white-star": index + 1 > review.star,
																})}
															/>
														</span>
													))}
												</div>
											</div>
										</div>
									</div>
									<div className="say__ri">
										<span>
											<Moment toNow>{review.created_at}</Moment>
										</span>
									</div>
								</div>
								<div className="say__text">
									<p>{review.message}</p>
								</div>
							</div>
						))}
						<div className="table__result result--last">
							<Pagination
								isLoading={isLoading}
								total={total}
								currentPage={currentPage}
								perPage={perPage}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Review;
