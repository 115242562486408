import React from "react";
import Logo from "components/Logo";
import { Link, useNavigate } from "react-router-dom";
import messageIcon from "assets/images/Message.png";
import { useForm } from "react-hook-form";
import { useForgotPasswordMutation } from "services/authApi";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPasswordMutation();
  const onSubmit = async (data) => {
    try {
      const response = await forgotPassword(data).unwrap();
      if (response) {
        navigate("/check-email", { state: { email: data.email } });
      }
    } catch ({ data: forgotPasswordError }) {
      forgotPasswordError?.data?.forEach(({ name, message }) => {
        setError(name, { type: "server", message });
      });
    }
  };
  return (
    <div className="singin-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="singin-box">
              <Logo />
              <div className="singin-title">
                <h3>Forgot password</h3>
                <p>
                  Enter your registered email address to receive password reset
                  instruction
                </p>
              </div>
              <div className="singin-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="single-input">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Your email"
                      className={`form-control ${errors.email && "is-invalid"}`}
                      {...register("email", {
                        required: "Email is required.",
                      })}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                  <div className="singin-btn">
                    <button type="submit">
                      <img src={messageIcon} alt="" />
                      Send Instruction
                    </button>
                  </div>
                  <div className="singin-text pd">
                    <p>
                      <Link to="/signin">Back to login</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
