import { baseApi } from "./baseApi";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: ["SubPlace"],
});

const subplaceApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getSubplace: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/subplaces/${id}`,
        providesTags: (id) => [{ type: "SubPlace", id }],
      }),
    }),
    createSubplace: builder.mutation({
      query: (data) => ({
        url: "/api/v1/creator/subplaces",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: "Places", id: result?.quest_id }],
    }),
    updateSubpace: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/creator/subplaces/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (result, _error, arg) => [
        { type: "Places", id: result?.quest_id },
        { type: "SubPlace", id: arg?.id },
      ],
    }),
    deleteSubplace: builder.mutation({
      query: (id) => ({
        url: `/api/v1/creator/subplaces/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [{ type: "Places", id: result?.quest_id }],
    }),
    getSubplaceTasks: builder.query({
      query: (id) => ({
        url: `/api/v1/creator/subplaces/${id}/tasks`,
      }),
      providesTags: (_result, _error, arg) => [
        { type: "SubPlaceTasks", id: arg },
      ],
    }),
  }),
});

export const {
  useGetSubplaceQuery,
  useCreateSubplaceMutation,
  useDeleteSubplaceMutation,
  useUpdateSubpaceMutation,
  useGetSubplaceTasksQuery,
} = subplaceApi;
