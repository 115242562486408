import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiChevronDown } from "react-icons/fi";
import { FaPlus, FaStar, FaTrash } from "react-icons/fa";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { v4 as uuid } from "uuid";
import { useDeleteDialog } from "components/DeleteDialog";
import HTMLView from "components/HTMLView";
import ClipboardIcon from "assets/images/icon/clipboard-check-outline.png";
import PlaceIcon from "assets/images/icon/PlaceIcon.png";
import { TaskType } from "utils/questUtils";
import RadioButtonIcon from "assets/images/icon/RadioButton.png";
import EditIcon from "assets/images/icon/Edit.png";
import { useListPlacesQuery } from "services/questApi";
import { useDeletePlaceMutation } from "services/placeApi";
import { useDeleteTaskMutation } from "services/taskApi";
import { useShowModal } from "utils/questModal";
import { setPlace } from "features/questSlice";
import { useDeleteSubplaceMutation } from "services/subplaceApi";
import InfoIcon from "assets/images/icon/info.png";
import ReactTooltip from "react-tooltip";
import EmptyPlaceList from "components/quest/EmptyPlaceList";
import sequenceIcon from "assets/images/icon/ArrowsDownUp.svg";

const ListPlace = (props) => {
	const { id, editable } = props;
	const { reFetchPlaces } = useSelector((state) => state.quest);
	const showModal = useShowModal();
	const { data: places, refetch, isLoading } = useListPlacesQuery(id);
	const [deletePlace] = useDeletePlaceMutation();
	const deleteDialog = useDeleteDialog();
	const haveHiddenPlace = places?.some((place) => place.is_hidden);

	const onEditPlaceClick = (id) => {
		showModal({ component: "PlaceFormModal", props: { id: id } });
	};

	const onDeletePlaceClick = (place) => {
		deleteDialog({ deleteHandler: deletePlace, id: place.id });
	};

	const onAddHiddenPlaceClick = () => {
		showModal({ component: "PlaceFormModal", props: { isHidden: true } });
	};

	useEffect(() => {
		if (reFetchPlaces) {
			refetch();
		}
	}, [refetch, reFetchPlaces]);

	return (
		<>
			{isLoading && <SkeletonPlace />}
			{places?.length === 0 && <EmptyPlaceList />}
			{places?.map((place) => (
				<div
					className={classNames("track-area", {
						qt5: editable,
						hpa: place.is_hidden,
					})}
					key={`place-${place.id}`}
				>
					<div className="track-left">
						<div className="track-img">
							<img src={place.image_url} alt="" />
						</div>
						<div className="track-left-text" style={{ marginBottom: "50px" }}>
							<h3 style={{ wordWrap: "break-word" }}>{place.title}</h3>
							<p>
								<img src={RadioButtonIcon} alt="" />
								<span>{place.radius} km</span>(Radius)
							</p>
							<p>
								<img src={ClipboardIcon} alt="" />
								<span>{place.tasks_count} Task</span>
							</p>
							{ place.is_hidden && (
								<p>
									<FaStar
										style={{
											color: "#FFCF50",
											width: "24px"
										}}
									/>
									<span>Hidden Place</span>
								</p>
							)}
						</div>

						{editable && (
							<div className="bat-title qt5">
								<a
									href="#0"
									onClick={() => onEditPlaceClick(place.id)}
									className="flex items-center"
								>
									<img src={EditIcon} alt="" />
									Edit Place
								</a>
								<a
									href="#0"
									onClick={() => onDeletePlaceClick(place)}
									className="plus del"
								>
									<FaTrash />
								</a>
							</div>
						)}
					</div>
					<div className="treck-right">
						{place.have_subplaces ? (
							<SubPlaceView place={place} {...props} />
						) : (
							<PlaceTaskView place={place} {...props} />
						)}
					</div>
				</div>
			))}
			{ editable && places?.length > 0 && !haveHiddenPlace && (
				<div className="hdn-palce">
					<a href="#0" onClick={onAddHiddenPlaceClick}>
						<img src={require("assets/images/icon/Star.png")} alt="" />
						&nbsp;Add Hidden Place?
					</a>
					<p className="flex items-center justify-center">
						What is Hidden Place?&nbsp;
						<img
							type="button"
							src={InfoIcon}
							alt=""
							data-tip
							data-for="hiddenPlaceTooltip"
						/>
						<ReactTooltip id="hiddenPlaceTooltip" effect="solid">
							<span>
								A place that will only be shown after all tasks from non-hidden
								places are finished
							</span>
						</ReactTooltip>
					</p>
				</div>
			)}
		</>
	);
};

export default ListPlace;

const TaskView = (props) => {
	const { task, place, editable } = props;
	const dispatch = useDispatch();
	const showModal = useShowModal();
	const [deleteTask] = useDeleteTaskMutation();
	const deleteDialog = useDeleteDialog();

	const onEditTaskClick = () => {
		dispatch(setPlace(place));
		showModal({ component: "TaskFormModal", props: { id: task.id } });
	};

	const onDeleteTaskClick = () => {
		deleteDialog({ deleteHandler: deleteTask, id: task.id });
	};
	return (
		<div className="track-ri-top-text">
			<div className="d-flex justify-content-start align-items-center w-100">
				<div className="" style={{ width: "75%" }}>
					<h3>{task.title}</h3>
					<p className="m-0">
						<TaskType type={task.task_type} />
					</p>
				</div>
				<div
					className="btn-group"
					style={{ width: "25%", display: "flex", justifyContent: "end" }}
				>
					{editable && (
						<div className="flex">
							<a
								href="#0"
								onClick={onEditTaskClick}
								className="my-auto px-2 dark-green fw-bold"
							>
								Edit
							</a>
							<a
								href="#0"
								className="p-1 text-danger"
								onClick={onDeleteTaskClick}
							>
								<FaTrash />
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const PlaceTaskView = (props) => {
	const showModal = useShowModal();
	const dispatch = useDispatch();

	const { place, editable } = props;

	const onAddTask = () => {
		dispatch(setPlace(place));
		showModal({ component: "TaskFormModal" });
	};

	const onEditTaskSequence = () => {
		showModal({
			component: "TaskSequenceModal",
			props: {
				parentId: place.id,
				parentType: "Place"
			}
    });
  };

	return (
		<>
			<div className="track-ri-top d-flex justify-content-between align-items-center">
				<span>
					<img src={ClipboardIcon} alt="" /> {place.tasks_count} Task
				</span>
				{editable && (
					<div>
						<button
							onClick={onAddTask}
							className="btn btn-sm btn-questeon outlined"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								float: "left",
								minWidth: "auto",
								marginRight: "15px"
							}}
						>
							<FaPlus />
							&nbsp;Add
						</button>
						<button
							onClick={onEditTaskSequence}
							className="btn btn-sm btn-questeon outlined"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderColor: "transparent",
								minWidth: "auto",
								paddingRight: "0",
								paddingLeft: "0"
							}}
						>
							<img src={sequenceIcon} alt="" />
						</button>
					</div>
				)}
			</div>
			<div className="track-ri-text">
				{place.tasks.map((task) => (
					<TaskView task={task} key={`task-${task.id}`} {...props} />
				))}
			</div>
		</>
	);
};

const SubPlaceView = (props) => {
	const { place, editable } = props;
	const showModal = useShowModal();
	const dispatch = useDispatch();
	const deleteDialog = useDeleteDialog();
	const [deleteSubplace] = useDeleteSubplaceMutation();
	const [toggle, setToggle] = useState("collapse");
	
	const onAddSubplace = () => {
		showModal({
			component: "SubplaceFormModal",
			props: { parentId: place.id },
		});
	};

	const onEditSubplaceClick = (id) => {
		showModal({
			component: "SubplaceFormModal",
			props: { id, place: place },
		});
	};

	const onDeleteSubplaceClick = (id) => {
		deleteDialog({ deleteHandler: deleteSubplace, id: id });
	};

	const onAddTask = (id) => {
		dispatch(setPlace(place));
		showModal({
			component: "TaskFormModal",
			props: { parentId: id, parentType: "Subplace" },
		});
	};

	const onEditTaskSequence = (id) => {
		showModal({
			component: "TaskSequenceModal",
			props: {
				parentId: id,
				parentType: "Subplace"
			}
    });
  };

	return (
		<>
			<div className="track-ri-top">
				<HTMLView content={place.description} />
				<div className="d-flex justify-content-between align-items-center">
					<span>
						<img src={PlaceIcon} alt="" /> {place.subplaces_count} Sub Place
					</span>
					{editable && (
						<button
							onClick={onAddSubplace}
							className="btn btn-sm btn-questeon outlined"
						>
							Add Subplace
						</button>
					)}
				</div>
				<span></span>
			</div>
			<div className="lavel-area">
				{place.subplaces.map((subplace) => (
					<div className="single-label" key={`subplace-${subplace.id}`}>
						<div
							className="label-top"
							type="button"
							data-bs-toggle={toggle}
							data-bs-target={`#subplace-${subplace.id}`}
							aria-expanded="false"
							aria-controls={`subplace-${subplace.id}`}
						>
							<div className="label-top-left">
								<div className="lavel-img">
									<img src={subplace.image_url} alt="" height={80} width={80} />
								</div>
								<div className="lavel-text">
									<h3>{subplace.title}</h3>
									<HTMLView content={subplace.description} />
									<div
										onMouseEnter={() => setToggle("")}
										onMouseLeave={() => setToggle("collapse")}
										className="subplace btn-group mt-2"
									>
										{ editable && (
											<>
												<a
													href="#0"
													onClick={() => onEditSubplaceClick(subplace.id)}
													className="my-auto pe-3 dark-green fw-bold"
												>
													Edit
												</a>
												<a
													href="#0"
													className="my-auto pe-3 red-1 fw-bold"
													onClick={() => onDeleteSubplaceClick(subplace.id)}
												>
													Delete
												</a>
											</>
										)}
									</div>
								</div>
							</div>
							<div className="label-icon">
								<a href="#0">
									<FiChevronDown color="white" />
								</a>
							</div>
						</div>

						<div
							className="label-bottom collapse"
							id={`subplace-${subplace.id}`}
						>
							<div className="track-ri-top">
								<div className="track-ri-top d-flex justify-content-between align-items-center">
									<span>
										<img src={ClipboardIcon} alt="" /> {subplace.tasks_count}{" "}
										Task
									</span>
									{editable && (
										<div>
											<button
												onClick={() => {
													onAddTask(subplace.id);
												}}
												className="btn btn-sm btn-questeon outlined"
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													float: "left",
													minWidth: "auto",
													marginRight: "15px"
												}}
											>
												<FaPlus />
												&nbsp;Add
											</button>
											<button
												onClick={() => {
													onEditTaskSequence(subplace.id);
												}}
												className="btn btn-sm btn-questeon outlined"
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													borderColor: "transparent",
													minWidth: "auto",
													paddingRight: "0",
													paddingLeft: "0"
												}}
											>
												<img src={sequenceIcon} alt="" />
											</button>
										</div>
									)}
								</div>
							</div>
							<div className="track-ri-text">
								{subplace.tasks.map((task) => (
									<TaskView task={task} key={`task-${task.id}`} {...props} />
								))}
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

const SkeletonTask = () => {
	return (
		<div className="track-ri-top-text" key={uuid}>
			<div>
				<Skeleton />
				<Skeleton />
				<Skeleton />
			</div>
		</div>
	);
};

const SkeletonPlace = ({ count = 2 }) => {
	return (
		<>
			{Array.from({ length: count }).map((_, index) => (
				<div className="track-area" key={`${index}-${uuid()}`}>
					<div className="track-left">
						<div className="track-img">
							<Skeleton height={200} width={200} />
						</div>
						<div className="track-left-text">
							<h3>
								<Skeleton width={250} />
							</h3>
							<p>
								<Skeleton width={150} />
							</p>
							<p>
								<Skeleton width={150} />
							</p>
						</div>
					</div>
					<div className="treck-right">
						<div className="track-ri-top">
							<p>
								<Skeleton />
							</p>
							<div className="d-flex justify-content-between align-items-center">
								<span>
									<Skeleton width={200} />
								</span>
							</div>
							<span></span>
						</div>
						<div className="lavel-area">
							<Skeleton />
							<Skeleton />
							<div className="track-ri-text">
								<br />
								{Array.from({ length: 2 }).map(() => (
									<SkeletonTask key={`${index}-${uuid()}`} />
								))}
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
};
