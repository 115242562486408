import React from "react";

const StatusDesc = ({ questStatus }) => {
  if (questStatus === "draft") {
    return (
      <span>
        This quest is still on your draft! Finish creating the quest,
        then submit for review to make it available online.
      </span>
    );
  };

  if (questStatus === "review") {
    return (
      <span>
        Your quest is under review now, this process may takes 2
				working days.
      </span>
    );
  };

  if (questStatus === "rejected") {
    return (
      <span>
        Update your quest and try submit it again.
      </span>
    );
  };

  if (questStatus === "ready") {
    return (
      <span>
        Your quest is ready to launch! Click "Publish Now" button to
				make it available online.
      </span>
    );
  };

  if (questStatus === "publish") {
    return (
      <span>
        Congratulations! Your quest is online and ready to played.
      </span>
    );
  };

  return <></>; 
};

export default StatusDesc;