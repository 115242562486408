import React, { forwardRef, useState, useEffect, useCallback } from "react";
import { useListBadgesQuery } from "services/questApi";
import MedalIcon from "assets/images/Medal.png";
import SelectInput from "./SelectInput";

const BadgeInput = forwardRef((props, ref) => {
  const { data, isLoading } = useListBadgesQuery();
  const [badge, setBadge] = useState(MedalIcon);
  const [options, setOptions] = useState([]);
  const { onChange, value } = props;

  const selectBadge = useCallback(
    (selected) => {
      const badge = data?.find((badge) => badge.id === Number(selected));
      return badge?.image_url;
    },
    [data]
  );

  const onBadgeChange = (selectedBadge) => {
    const badge = selectBadge(selectBadge);
    setBadge(badge);
    onChange(selectedBadge);
  };

  useEffect(() => {
    const options = data?.map((badge) => ({
      label: badge.title,
      value: badge.id,
    }));
    setOptions(options);
    if (value) {
      setBadge(selectBadge(value));
    }
  }, [data, value, selectBadge]);

  return (
    <div className="reword-fl">
      <div className="reword-img circle-img me-3">
        <img src={badge} alt="" />
      </div>
      <div className="reword-select">
        <SelectInput
          ref={ref}
          isLoading={isLoading}
          onChange={({ value }) => {
            onBadgeChange(value);
          }}
          options={options}
          isSearchable={true}
          overridingOnChange={true}
          value={value}
        />
      </div>
    </div>
  );
});

export default BadgeInput;
