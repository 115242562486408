import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  component: null,
  props: {},
};

const modalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.show = false;
    },
    showModal: (state, { payload }) => {
      state.show = true;
      state.component = payload.component;
      state.props = payload.props;
    },
  },
});

export const { closeModal, showModal } = modalSlice.actions;
export default modalSlice.reducer;
