import React, { useEffect, useRef } from "react";
import EditIcon from "assets/images/icon/Edit.png";
import InfoIcon from "assets/images/icon/info.png";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import AvatarInput from "components/inputs/AvatarInput";
import {
  useGetCreatorQuery,
  useUpdateCreatorMutation,
} from "services/creatorApi";
import { serializeData } from "utils/serializeData";
import RichTextInput from "components/inputs/RichTextInput";

const Setting = () => {
  const showComponent = false; // Hide currently unused inputs
  const dispatch = useDispatch();
  const paypalRef = useRef();
  const { quest } = useSelector((state) => state.quest);
  const { data } = useGetCreatorQuery();
  const [updateCreator] = useUpdateCreatorMutation();
  const emptySetting = {
    name: "",
    email: "",
    paypal_email: "",
    description: "",
    avatar: "",
  };

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: emptySetting,
  });

  useEffect(() => {
    if (showComponent) {
      new Tooltip(paypalRef.current, {
        title: "Paypal Email",
        placement: "top",
        trigger: "hover",
      });
    }
  }, [dispatch, paypalRef, showComponent]);

  useEffect(() => {
    if (data) {
      const settingValue = {
        name: data?.name,
        email: data?.email,
        paypal_email: data?.paypal_email,
        description: data?.description,
        avatar: data?.avatar_url,
      };
      reset(settingValue);
    }
  }, [quest, reset, data]);

  const handleError = (errors) => {
    errors?.data?.forEach(({ name, message }) => {
      setError(name, { type: "server", message });
    });
  };

  const onSubmit = async (data) => {
    const creatorDataForm = serializeData({ creator: data });
    try {
      const response = await updateCreator(creatorDataForm).unwrap();
      if (response) {
        alert("Your changes has been saved!");
      }
    } catch ({ data }) {
      handleError(data);
    }
  };

  return (
    <div className="main-content-body">
      <div className="dash-tab-create">
        <div className="info-containt">
          <div className="info-title " style={{ marginTop: "20px" }}>
            <h3>Settings</h3>
          </div>
          <div className="sinfo-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="title-bold black "
                style={{ marginBottom: "24px" }}
              >
                Account Info
              </div>
              <div className="single-info">
                <label>Avatar</label>
                <Controller
                  name="avatar"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <AvatarInput
                      {...field}
                      onError={setError}
                      error={errors.avatar}
                    />
                  )}
                />
                {errors?.avatar && (
                  <div className="text-danger">{errors?.avatar?.message}</div>
                )}
              </div>
              <div className="single-info">
                <label>Full Name</label>
                <div className="single-input">
                  <input
                    type="text"
                    placeholder="Type Full Name"
                    className={classNames("form-control", {
                      "is-invalid": errors.name,
                    })}
                    {...register("name", {
                      required: "Full Name is required",
                    })}
                  />

                  {errors?.name && (
                    <div className="invalid-feedback">
                      {errors?.name?.message}
                    </div>
                  )}
                </div>
              </div>

              { showComponent && (
                <div className="single-info">
                  <label>Bio</label>
                  <div className="single-input">
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RichTextInput
                          {...field}
                          placeholder="Type Your Bio"
                        />
                      )}
                    />
                    { errors?.description && (
                      <div className="invalid-feedback">
                        { errors?.description?.message }
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="single-info">
                <label>Email</label>
                <div className="single-input brightness-90">
                  <input
                    type="text"
                    disabled
                    control={control}
                    placeholder="Type Email"
                    {...register("email")}
                  />
                </div>
              </div>
              { showComponent && (
                <>
                  <div
                    className="title-bold black"
                    style={{ marginBottom: "24px" }}
                  >
                    Payments
                  </div>
              
                  <div className="single-info">
                    <label>
                      <div className="flex items-center">
                        PayPal Email{" "}
                        <img
                          type="button"
                          ref={paypalRef}
                          src={InfoIcon}
                          alt=""
                          style={{
                            width: "16px",
                            height: "16px",
                          }}
                          className="ml-3"
                        />
                      </div>
                    </label>
                    <div className="single-input">
                      <input
                        type="email"
                        placeholder="Type PayPal Email"
                        className={classNames("form-control", {
                          "is-invalid": errors?.paypal_email
                        })}
                        {...register("paypal_email", {
                          required: "Paypal Email required",
                        })}
                      />
                      {errors?.paypal_email && (
                        <div className="invalid-feedback">
                          {errors?.paypal_email?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div
                className="title-bold black "
                style={{ marginBottom: "24px" }}
              >
                Password
              </div>

              <div className="single-info">
                <label>Change Password</label>
                <div className="single-input-add">
                  <Link to="/setting/change-password">
                    <img src={EditIcon} alt="" /> Change My Password
                  </Link>
                </div>
              </div>

              <div className="single-info">
                <label></label>
                <div className="single-input-add">
                  <button
                    type="submit"
                    className="btn-green"
                    style={{ height: "50px" }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
