import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setHeader, setHeaderTitle } from "features/navigationSlice";
import { useNavigate } from "react-router-dom";
import { useChangePasswordMutation } from "services/creatorApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ChangePassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [changePassword] = useChangePasswordMutation();
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
	const confirmButtonColor = "#2C7D92";

	useEffect(() => {
		dispatch(setHeader("SETTING"));
		dispatch(setHeaderTitle("Change Password"));
	}, [dispatch]);

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm();

	// validation for new pass and retype new pass
	const password = useRef({});
	password.current = watch("password", "");

	const handleShowCurrentPassword = () => {
		setShowCurrentPassword(!showCurrentPassword);
	};

	const handleShowNewPassword = () => {
		setShowNewPassword(!showNewPassword);
	};

	const handleShowConfirmNewPassword = () => {
		setShowConfirmNewPassword(!showConfirmNewPassword);
	};

	const onSubmit = async (data) => {
		try {
			const response = await changePassword({ creator: data }).unwrap();
			if (response) {
				reset();
				Swal.fire({
					title: "Success!",
					text: "Success",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor,
				});
			}
		} catch ({ data }) {
			Swal.fire({
				title: "Error!",
				text: data.errors,
				icon: "error",
				confirmButtonText: "Ok",
				confirmButtonColor,
			});
		}
	};

	return (
		<div className="main-content-body">
			<div className="dash-tab-create">
				<div className="info-containt">
					<div className="info-title mt-20">
						<h3>Settings</h3>
					</div>
					<div className="sinfo-form">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="single-info">
								<label>Current Password</label>
								<div className="single-input flex items-center">
									<input
										type={showCurrentPassword ? "text" : "password"}
										placeholder="Type Current Password"
										{...register("current_password", {
											required: "Current password is required",
										})}
									/>
									{errors?.current_password && (
										<div className="invalid-feedback">
											{errors?.current_password?.message}
										</div>
									)}
									<FontAwesomeIcon
										onClick={handleShowCurrentPassword}
										icon={showCurrentPassword ? faEyeSlash : faEye}
										cursor="pointer"
										className="-ml-8"
									/>
								</div>
							</div>

							<div className="single-info">
								<label>New Password</label>
								<div className="single-input flex items-center">
									<input
										type={showNewPassword ? "text" : "password"}
										placeholder="Type New Password"
										{...register("password", {
											required: "You must specify a password",
											minLength: {
												value: 8,
												message: "Password must have at least 8 characters",
											},
										})}
									/>
									{errors.password && <p>{errors.password.message}</p>}
									<FontAwesomeIcon
										onClick={handleShowNewPassword}
										icon={showNewPassword ? faEyeSlash : faEye}
										cursor="pointer"
										className="-ml-8"
									/>
								</div>
							</div>

							<div className="single-info">
								<label>Confirm New Password</label>
								<div className="single-input flex items-center">
									<input
										name="confirmPassword"
										placeholder="Re-Type New Password"
										type={showConfirmNewPassword ? "text" : "password"}
										{...register("password_confirmation", {
											validate: (value) =>
												value === password.current ||
												"The passwords do not match",
										})}
									/>
									{errors.password_confirmation && (
										<p>{errors.password_confirmation.message}</p>
									)}
									<FontAwesomeIcon
										onClick={handleShowConfirmNewPassword}
										icon={showConfirmNewPassword ? faEyeSlash : faEye}
										cursor="pointer"
										className="-ml-8"
									/>
								</div>
							</div>

							<div className="single-info">
								<label></label>
								<div
									className="single-input-add flex gap-24"
									style={{ display: "flex", gap: "24px" }}
								>
									<button
										type="submit"
										className="btn-green"
										style={{ height: "50px" }}
									>
										Save Changes
									</button>

									<button
										className="btn-cancel"
										style={{ height: "50px" }}
										onClick={() => {
											navigate("/setting");
										}}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
