import React from "react";
import logo from "assets/images/logo.png";
import { Link } from "react-router-dom";
import MyQuestIcon from "assets/images/sidebar/d-h1.png";
import UserBaseIcon from "assets/images/sidebar/d-h2.png";
import EarningIcon from "assets/images/sidebar/d-h3.png";
import SettingsIcon from "assets/images/sidebar/d-h4.png";
import LogoutIcon from "assets/images/sidebar/Logout.png";
import defaultUserImage from "assets/images/dash-user.png";
import Skeleton from "react-loading-skeleton";
import { useSignOutMutation } from "services/authApi";
import { useGetCreatorQuery } from "services/creatorApi";
import classNames from "classnames";
import { useSelector } from "react-redux";
import useWindowDimensions from "hooks/WindowDimensions";

const Sidebar = () => {
	const [signOut] = useSignOutMutation();
	const handleSignOut = async () => {
		await signOut();
	};
	const { data, isLoading } = useGetCreatorQuery();
	const { active: navigationActive } = useSelector((state) => state.navigation);
	const { height } = useWindowDimensions();

	return (
		<div
			className={classNames("sidebar", { active: navigationActive })}
			style={{
				height: height,
			}}
		>
			<div className="sidebar-logo">
				<Link to="/">
					<img src={logo} alt="" />
				</Link>
			</div>
			<div className="sidebar-content">
				<div className="sidebar-content-top">
					<div className="user">
						<div className="user-img">
							{isLoading && <Skeleton circle height="100px" width={100} />}
							<img
								src={data?.avatar_url ? data.avatar_url : defaultUserImage}
								alt={data?.name}
								style={{ display: isLoading ? "none" : undefined }}
							/>
						</div>
						<div className="user-text">
							<h3>{isLoading ? <Skeleton width={200} /> : data?.name}</h3>
							<span>{isLoading ? <Skeleton width={200} /> : data?.email}</span>
						</div>
					</div>

					<div className="sidebar-menu">
						<ul>
							<li>
								<Link to="/quests">
									<img src={MyQuestIcon} alt="" /> My Quest
								</Link>
							</li>
							<li style={{ display: "none" }}>
								<Link to="/">
									<img src={UserBaseIcon} alt="" /> User Base
								</Link>
							</li>
							<li style={{ display: "none" }}>
								<Link to="/">
									<img src={EarningIcon} alt="" /> Earnings
								</Link>
							</li>
							<li>
								<Link to="/setting">
									<img src={SettingsIcon} alt="" /> Settings
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="sidebar-logout">
					<a href="#0" onClick={handleSignOut} className="flex">
						<img src={LogoutIcon} alt="" />
						Sign Out
					</a>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
