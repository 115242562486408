import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaPen, FaTrash } from "react-icons/fa";
import loadable from "@loadable/component";
import classNames from "classnames";
import ListTask from "pages/quest/TaskDetail/ListTask";
import { useGetTaskQuery } from "services/taskApi";
import { useShowModal } from "utils/questModal";
import HTMLView from "components/HTMLView";
import Cover from "pages/quest/TaskDetail/Cover";
import { setAction } from "features/questSlice";
import { setHeader } from "features/navigationSlice";
import { useGetQuestQuery } from "services/questApi";
import { setQuest } from "features/questSlice";
import TaskClueView from "./TaskDetail/TaskClue";

const TaskDetail = () => {
	const { id, questId } = useParams();
	const { data } = useGetTaskQuery(id);
	const { data: quest } = useGetQuestQuery(questId);
	const showModal = useShowModal();
	const dispatch = useDispatch();

	const onEditTaskClick = () => {
		showModal({ component: "TaskFormModal", props: { id } });
	};

	const { task_type: taskType } = data || {};

	const taskTypeViewComponent = useMemo(() => {
		const components = {
			action: "TaskActionView",
			check_in: "TaskCheckInView",
			information: "TaskInformationView",
			short_answer: "TaskShortAnswerView",
			polling: "TaskPollingView",
			photo: "TaskPhotoView",
			guess_the_difference: "TaskSpotTheDifferenceView",
			scan_qr: "TaskScanQRView",
			multiple_choice: "TaskMultipleChoiceView",
			augmented_reality: "TaskAugmentedRealityView",
		};
		const taskViewComponent = components[taskType];
		return taskViewComponent;
	}, [taskType]);

	const SelectedTaskTypeView = useMemo(() => {
		if (taskType) {
			return loadable(
				() => import(`./TaskDetail/TaskType/${taskTypeViewComponent}`),
				{
					fallback: <h3>Loading...</h3>,
				}
			);
		}
	}, [taskTypeViewComponent, taskType]);

	useEffect(() => {
		dispatch(setAction("VIEW"));
		dispatch(setHeader("QUEST"));
		if (quest) {
			dispatch(setQuest(quest));
		}
	}, [dispatch, quest]);

	return (
		<div className="botanic__area">
			{data && (
				<>
					<div className="botanic__top">
						<div className="botanic__title">
							<h3>{data.title}</h3>
						</div>
						<div className="botanic__button">
							<button className="edt btn" onClick={onEditTaskClick}>
								<FaPen />
								Edit
							</button>
							<span>
								<FaTrash />
							</span>
						</div>
					</div>
					<div className="user__wrap">
						<div className="user__fx">
							<div className="user__lf">
								<div className="view__task__wrap">
									<Cover task={data} />
									<div className="view__task__ck">
										<ul>
											<li>
												<img
													src={require("assets/images/view__task/ck.png")}
													alt=""
												/>
												{data.points} XP
											</li>
											{data.inside_radius && (
												<li>
													<img
														src={require("assets/images/view__task/radio.png")}
														alt=""
													/>
													Inside Radius
												</li>
											)}
										</ul>
									</div>
									<div className="view__task--text">
										<HTMLView content={data.description} />
									</div>
									<SelectedTaskTypeView taskData={data} />
									{data.task_hints.length > 0 && (
										<div className="hints__wrap">
											<h4>Hints 💡</h4>
											{data.task_hints.map((hint, index) => (
												<div
													className={classNames("hints_single--item", {
														"hints--thr": index === 2,
													})}
													key={hint.id}
												>
													<p>{hint.content}</p>
													<span>{index + 1}</span>
												</div>
											))}
										</div>
									)}
									<TaskClueView taskData={data} />
								</div>
							</div>
							<ListTask />
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default TaskDetail;
