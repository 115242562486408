import React, { useState } from "react";
import Logo from "components/Logo";
import EnvelopeOpenIcon from "assets/images/EnvelopeOpen.png";
import { useLocation } from "react-router-dom";
import { useSendEmailConfirmationMutation } from "services/authApi";
import AuthNotice from "components/AuthNotice";

const VerifyEmail = () => {
  const { state } = useLocation();
  const [resendConfirmation] = useSendEmailConfirmationMutation();
  const [resendNotice, setResendNotice] = useState({
    type: "",
    message: ""
  });

  const onSubmit = async () => {
    try {
      const result = await resendConfirmation({
        email: state?.email,
      }).unwrap();
      if(result){
        setResendNotice({
          type: "success",
          message: "A new email instruction has been sent to your email"
        });
      }
    } catch ({ data: resendEmailError }) {
      setResendNotice({
        type: "error",
        message: resendEmailError.errors
      });
    }
  };

  return (
    <div className="singin-area">
      { resendNotice.message.length !== 0 && (
        <AuthNotice 
          type={resendNotice.type}
          message={resendNotice.message}
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="singin-box">
              <div className="verify-box">
                <Logo />
                <div className="verify-containt">
                  <div className="verify-img">
                    <img src={EnvelopeOpenIcon} alt="" />
                  </div>
                  <div className="verify-text">
                    <h3>Verify your email</h3>
                    <p>
                      An instruction email has been sent to <br />
                      <a href="#0">{state?.email}</a>
                    </p>
                    <p>
                      If you have not received the email after a few <br />{" "}
                      minutes, please check your spam folder
                    </p>
                  </div>
                </div>
                <div className="verify-box-last-text">
                  <p>
                    Still not receiving email?&nbsp;
                    <button
                      onClick={() => {
                        onSubmit()
                      }}
                    >
                      Resend email
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
