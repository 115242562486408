import React from "react";
import { v4 as uuid } from "uuid";
import { FiChevronDown } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useListPlacesQuery } from "services/questApi";
import HTMLView from "components/HTMLView";
import { TaskType } from "utils/questUtils";
import EmptyPlaces from "pages/quest/QuestDetail/EmptyStates/EmptyPlaces";

const Places = () => {
	const { id } = useParams();
	const { quest } = useSelector((state) => state.quest);
	const { data: places } = useListPlacesQuery(id);
	const emptyPlaceList = places?.length === 0;

	const TaskView = ({ task, parentType = "places", parentId }) => {
		return (
			<div className="place__pond__single--box px-4">
				<div className="place__pond--contnt">
					<h5>{task.title}</h5>
					<p>
						<TaskType type={task.task_type} />
					</p>
				</div>
				<div className="place__details">
					<Link
						to={`/quests/${quest?.slug}/${parentType}/${parentId}/tasks/${task.id}`}
					>
						Show&nbsp;Detail
					</Link>
				</div>
			</div>
		);
	};

	const ListTasks = ({ tasks, tasksCount, parentId }) => {
		return (
			<div className="place__ri my-2 mx-2 rounded-l-xl " key={uuid()}>
				<div className="place__cal">
					<span>
						<img
							src={require("assets/images/icon/clipboard-check-outline.png")}
							alt=""
						/>
						&nbsp;{tasksCount} Task
					</span>
				</div>
				{tasks.map((task) => (
					<TaskView task={task} key={uuid()} parentId={parentId} />
				))}
			</div>
		);
	};

	const SubplaceView = ({ subplace }) => {
		return (
			<div className="chupit__box">
				<div
					className="chupit__top"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target={`#subplace-${subplace.id}`}
					aria-expanded="false"
					aria-controls={`#subplace-${subplace.id}`}
				>
					<div className="chupit__top--lf">
						<div className="chupit__img">
							<img src={subplace.image_url} alt="" />
						</div>
						<div className="chupit__content">
							<h5>{subplace.title}</h5>
							<HTMLView content={subplace.description} />
						</div>
					</div>
					<div className="chupit__icon">
						<span>
							<FiChevronDown color="white" />
						</span>
					</div>
				</div>
				<div className="place__drp collapse" id={`subplace-${subplace.id}`}>
					<div className="chupuit__task">
						<ul>
							<li>
								<img src={require("assets/images/calender.png")} alt="" />
								{subplace.tasks_count}
								&nbsp;Task
							</li>
						</ul>
					</div>
					{subplace.tasks.map((task) => (
						<TaskView
							task={task}
							key={uuid()}
							parentId={subplace.id}
							parentType={"subplaces"}
						/>
					))}
				</div>
			</div>
		);
	};

	const ListSubplaces = ({ subplaceCount, description, subplaces }) => {
		return (
			<div className="place__ri">
				<div className="place__wuay--tx">
					<HTMLView content={description} />
					<span>
						<img src={require("assets/images/icon/PlaceIcon.png")} alt="" />
						&nbsp;{subplaceCount} Subplace
					</span>
				</div>
				{subplaces.map((subplace) => (
					<SubplaceView subplace={subplace} key={uuid()} />
				))}
			</div>
		);
	};

	return (
		<>
			{emptyPlaceList && <EmptyPlaces />}
			{!emptyPlaceList && quest && (
				<div className="place__wrap ">
					<div className="place__top__task">
						<div className="place__top--title">
							<h4>📌 Places & Tasks</h4>
						</div>
						<div className="place__menu">
							<ul>
								<li>
									<div className="flex items-center">
										<img
											src={require("assets/images/icon/PlaceIcon.png")}
											alt=""
										/>
										{quest.places_count} Places
									</div>
								</li>
								<li>
									<div className="flex items-center">
										<img
											src={require("assets/images/icon/clipboard-check-outline.png")}
											alt=""
										/>
										{quest.tasks_count} Tasks
									</div>
								</li>
							</ul>
						</div>
					</div>

					{places?.map((place) => (
						<div
							className="flex items-center justify-between border-2 bg-[#F7F8FA] rounded-2xl my-4"
							key={place.id}
						>
							<div className="place__lf bg-[#F7F8FA] mr-2 rounded-l-xl ">
								<div className="place__img">
									<img src={place.image_url} alt="" />
								</div>
								<div className="place__img--title">
									<div
										className={
											place.title.length > 50
												? "h-28 text-ellipsis mb-2 overflow-hidden"
												: ""
										}
									>
										<h4 className="text-ellipsis overflow-hidden ">
											{place.title}
										</h4>
									</div>
									<div className="flex items-center font-medium text-sm pb-2">
										<img
											src={require("assets/images/icon/RadioButton.png")}
											alt=""
										/>
										&nbsp;{place.radius}&nbsp;km(Radius)
									</div>
									<div className="flex items-center text-sm font-medium">
										<img
											src={require("assets/images/icon/clipboard-check-outline.png")}
											alt=""
										/>
										&nbsp;{place.tasks_count} Tasks
									</div>
								</div>
							</div>
							{place.have_subplaces ? (
								<ListSubplaces
									subplaces={place.subplaces}
									subplaceCount={place.subplaces_count}
									description={place.description}
								/>
							) : (
								<ListTasks
									tasks={place.tasks}
									tasksCount={place.tasks_count}
									parentId={place.id}
								/>
							)}
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default Places;
