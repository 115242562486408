import React from "react";
import emptyUsersIcon from "assets/images/icon-empty-users.svg";

const EmptyUsers = () => {
	return (
		<div className="dash-tab-containt py-7">
			<div className="dash-tab-img grid justify-center">
				<img src={emptyUsersIcon} alt="" />
			</div>
			<div className="dash-tab-text mb-4">
				<h3>No user found</h3>
				<p>People haven't played this quest yet</p>
			</div>
		</div>
	);
};

export default EmptyUsers;
