import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FaPlus } from "react-icons/fa";
import sequenceIcon from "assets/images/icon/ArrowsDownUp.svg";
import ListPlace from "./ListPlace";
import { showModal } from "features/modalSlice";

const PlaceAndTaskForm = () => {
  const dispatch = useDispatch();
  const { quest: data } = useSelector((state) => state.quest);

  const onAddPlaceClick = () => {
    if (data) {
      dispatch(showModal({ component: "PlaceFormModal" }));
    }
  };

  const onEditSequenceClick = () => {
    if (data) {
      dispatch(showModal({ component: "PlaceSequenceModal" }));
    }
  };

  return (
    <div className="info-containt">
      <div className="info-title q5">
        <h3>
          <span>3</span> Places & Tasks
        </h3>
        <div className="btn-ctn">
          <a href="#0" onClick={onAddPlaceClick}>
            <FaPlus />
            &nbsp; Add Place
          </a>
          <a
            href="#0"
            className="transparent-btn"
            onClick={onEditSequenceClick}
          >
            Edit Sequence
            <img src={sequenceIcon} alt="" />
          </a>
        </div>
      </div>

      { data && <ListPlace id={data?.id} editable={true} /> }
      
      <div className="form-single-btn">
        <Link to={`/quests/${data?.slug}/edit/intro`} className="priv btn-nav">
          Previous
        </Link>
        <Link to={`/quests/${data?.slug}/edit/conclusion`} className="btn-nav">
          Next
        </Link>
      </div>
    </div>
  );
};

export default PlaceAndTaskForm;
