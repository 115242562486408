import React, { useEffect } from "react";
import { FaTrash, FaPlus } from "react-icons/fa";
import InfoIcon from "assets/images/icon/info.png";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
	resetNavigation,
	setNavigationQuestInfo,
	setQuest,
} from "features/questSlice";
import {
	useCreateQuestMutation,
	useUpdateQuestMutation,
} from "services/questApi";
import { serializeData } from "utils/serializeData";
import { useNavigate } from "react-router-dom";
import ImageInput from "components/inputs/ImageInput";
import RichTextInput from "components/inputs/RichTextInput";
import BadgeInput from "components/inputs/BadgeInput";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

const QuestForm = () => {
	const dispatch = useDispatch();
	const { quest } = useSelector((state) => state.quest);

	const emptyHighlight = {
		value: "",
	};

	const emptyQuest = {
		title: "",
		description: "",
		image: "",
		badge_id: "",
		route: 0,
		price: 0,
		highlights: [emptyHighlight],
		place_sequential: false,
		time_tracker: false,
	};

	const [createQuest] = useCreateQuestMutation();
	const [updateQuest] = useUpdateQuestMutation();
	const navigate = useNavigate();

	const {
		register,
		control,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: emptyQuest,
	});

	const { fields, append, remove } = useFieldArray({
		name: "highlights",
		control,
	});

	useEffect(() => {
		dispatch(resetNavigation());
		dispatch(setNavigationQuestInfo(true));
	}, [dispatch]);

	useEffect(() => {
		if (quest) {
			const highlights = quest?.highlights?.map((highlight) => ({
				value: highlight,
			}));

			const questValue = {
				title: quest?.title,
				description: quest?.description,
				image: quest?.image_url,
				badge_id: quest?.badge?.id,
				route: quest?.route,
				price: quest?.price,
				highlights: highlights,
				place_sequential: quest?.place_sequential,
				time_tracker: quest?.time_tracker,
			};
			reset(questValue);
		}
	}, [quest, reset]);

	const handleQuestCreate = async (data) => {
		try {
			const questData = await createQuest(data).unwrap();
			if (questData) {
				dispatch(setQuest(questData));
				navigate(`/quests/${questData?.slug}/edit/intro`);
			}
		} catch ({ data: createQuestError }) {
			handleError(createQuestError);
		}
	};

	const handleQuestUpdate = async (id, data) => {
		try {
			const questData = await updateQuest({
				id: id,
				quest: data,
			}).unwrap();
			if (questData) {
				dispatch(setQuest(questData));
				// alert("Your changes have been saved!");
				navigate(`/quests/${questData?.slug}/edit/intro`);
			}
		} catch ({ data: updateQuestError }) {
			handleError(updateQuestError);
		}
	};

	const handleError = (errors) => {
		errors?.data?.forEach(({ name, message }) => {
			setError(name, { type: "server", message });
		});
	};

	const onSubmit = async (data) => {
		const questDataForm = serializeData({ quest: data });
		quest
			? handleQuestUpdate(quest.id, questDataForm)
			: handleQuestCreate(questDataForm);
	};

	return (
		<div className="info-containt">
			<div className="info-title">
				<h3>
					<span>1</span> Quest Info
				</h3>
			</div>
			<div className="sinfo-form">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="single-info">
						<label>
							Quest Banner <sup>*</sup>
						</label>
						<div>
							<Controller
								name="image"
								control={control}
								defaultValue=""
								rules={{ required: "Quest Banner is required" }}
								render={({ field }) => (
									<ImageInput
										{...field}
										onError={setError}
										error={errors.image}
									/>
								)}
							/>
							{errors?.image && (
								<div className="text-danger">
									{errors?.image?.message}
								</div>
							)}
						</div>
					</div>
					<div className="single-info">
						<label>
							Quest Title <sup>*</sup>
						</label>
						<div className="single-input">
							<input
								type="text"
								placeholder=" Quest title"
								className={classNames("form-control", {
									"is-invalid": errors?.title
								})}
								{...register("title", { required: "Title is required" })}
							/>
							{errors?.title && (
								<div className="invalid-feedback">
									{errors?.title?.message}
								</div>
							)}
						</div>
					</div>
					<div className="single-info">
						<label>
							Quest Description <sup>*</sup>
						</label>
						<div className="single-input">
							<Controller
								name="description"
								control={control}
								defaultValue=""
								render={({ field }) => (
									<RichTextInput
										{...field}
										placeholder="Add description about the quest"
									/>
								)}
							/>
							{errors?.description && (
								<div className="invalid-feedback">
									{errors?.description?.message}
								</div>
							)}
						</div>
					</div>
					<div className="single-info">
						<label>
							Badge Reward <sup>*</sup>
						</label>
						<div className="single-input">
							<Controller
								name="badge_id"
								control={control}
								defaultValue=""
								rules={{ required: "Badge is required" }}
								render={({ field }) => <BadgeInput {...field} />}
							/>
							{errors?.badge_id && (
								<div className="text-danger">
									{errors?.badge_id?.message}
								</div>
							)}
						</div>
					</div>
					<div className="single-info wd">
						<label>
							Route<sup>*</sup>
						</label>
						<div className="single-input">
							<input
								type="number"
								placeholder="0"
								className={classNames("form-control", {
									"is-invalid": errors?.route
								})}
								{...register("route", { required: "Route is required" })}
							/>
							<span className={classNames("", {
								"is-invalid": errors?.route
							})}
							>
								Km
							</span>
							{errors?.route && (
								<div className="invalid-feedback">{errors?.route?.message}</div>
							)}
						</div>
					</div>
					<div className="single-info wd">
						<label>
							Price<sup>*</sup>
						</label>
						<div className="single-input">
							<input
								type="number"
								className={classNames("form-control", {
									"is-invalid": errors?.price
								})}
								placeholder="0"
								{...register("price", { required: "Price is required" })}
							/>
							<span
								className={classNames("", {
									"is-invalid": errors?.price
								})}
							>
								SGD
							</span>
							{errors?.price && (
								<div className="invalid-feedback">{errors?.price?.message}</div>
							)}
						</div>
						<p>
							0 SGD mean the quest will become <b>FREE</b>
						</p>
					</div>
					<div className="single-info">
						<label>Highlights</label>
						<div className="single-inputes">
							{fields.map((item, index) => (
								<div className="single-input" key={item.id}>
									<input
										type="text"
										defaultValue={`${item.value}`}
										className={classNames("form-control", {
											"is-invalid": errors?.["highlights"]?.[index]?.value
										})}
										placeholder="Type highlight"
										{...register(`highlights[${index}].value`, {
											required: "Highlight is required",
										})}
									/>
									{index > 0 && (
										<a
											href="#0"
											onClick={() => {
												remove(index);
											}}
											style={{ color: "#F15A5B" }}
										>
											<FaTrash />
										</a>
									)}
									{errors?.["highlights"]?.[index] && (
										<div className="invalid-feedback">
											{errors?.["highlights"]?.[index]?.value?.message}
										</div>
									)}
								</div>
							))}
							<div className="single-input-add">
								<a
									href="#0"
									onClick={() => {
										append({ value: "" });
									}}
								>
									<FaPlus />
									&nbsp; Add more highlight
								</a>
							</div>
						</div>
					</div>
					<div className="single-info">
						<label>Features</label>
						<div className="single-check-fl">
							<div className="single-check">
								<label className="containerss">
									<div className="flex items-center">
										Place Sequential&nbsp;
										<img
											type="button"
											src={InfoIcon}
											alt=""
											data-tip
											data-for="placeSequentialTooltip"
										/>
										<ReactTooltip id="placeSequentialTooltip" effect="solid">
											<span>
												The previous place's tasks must be finished before
												starting the next place
											</span>
										</ReactTooltip>
										<input type="checkbox" {...register("place_sequential")} />
										<span className="checkmark"></span>
									</div>
								</label>
							</div>
							<div className="single-check">
								<label className="containerss">
									<div className="flex items-center">
										Time Tracker&nbsp;
										<img
											type="button"
											src={InfoIcon}
											alt=""
											data-tip
											data-for="timeTrackerTooltip"
										/>
										<ReactTooltip id="timeTrackerTooltip" effect="solid">
											<span>
												A timer will be set from starting to finishing the quest
											</span>
										</ReactTooltip>
										<input type="checkbox" {...register("time_tracker")} />
										<span className="checkmark"></span>
									</div>
								</label>
							</div>
						</div>
					</div>
					<div className="form-single-btn">
						<button type="submit" className="btn-nav">
							Next
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default QuestForm;
