import React, { useState, useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { FaSearch, FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";

import Pagination from "components/Pagination";
import { useListQuestsQuery } from "services/questApi";
import { QuestStatus } from "utils/questUtils";
import EmptyQuest from "./EmptyQuest";

const ListQuest = () => {
	let [searchParams, setSearchParams] = useSearchParams();
	const pageQuery = searchParams.get("page") || 1;
	const searchQuery = searchParams.get("query") || "";
	const [page, setPage] = useState(pageQuery);
	const [search, setSearch] = useState(searchQuery);
	const [sortConfig, setSortConfig] = useState(null);
	const queryParams = useMemo(() => {
		let query = { page, search };
		if (sortConfig) {
			return { ...query, sort: sortConfig.sort, field: sortConfig.field };
		}
		return query;
	}, [page, search, sortConfig]);

	const { data, isFetching } = useListQuestsQuery(queryParams);
	const { quests, total, perPage, page: currentPage } = data || {};

	const requestSort = (field) => {
		let sort = "asc";
		if (sortConfig && sortConfig.field === field && sortConfig.sort === "asc") {
			sort = "desc";
		}
		setSortConfig({ sort, field });
	};

	const onPageChange = (page) => {
		searchParams.set("page", page);
		setSearchParams(searchParams);
		setPage(page);
	};

	const onSearchChange = (query) => {
		searchParams.set("query", query);
		searchParams.delete("page");
		setSearchParams(searchParams);
		setSearch(query);
		setPage(1);
	};

	const sortIcon = useCallback(
		(field) => {
			if (sortConfig && sortConfig.field === field) {
				if (sortConfig.sort === "asc") {
					return <FaSortUp />;
				} else {
					return <FaSortDown />;
				}
			}
			return <FaSort />;
		},
		[sortConfig]
	);

	return (
		<>
			{isFetching && <SkeletonQuestList />}
			{quests?.length === 0 && <EmptyQuest />}
			{!isFetching && quests?.length > 0 && (
				<div className="table-area">
					<div className="table-search">
						<input
							type="text"
							placeholder="Search Quest title"
							defaultValue={search}
							onChange={(event) => onSearchChange(event.target.value)}
						/>
						<a href="#0">
							<FaSearch />
						</a>
					</div>
					<div className="table-box table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th scope="col" onClick={() => requestSort("title")}>
										<div className="flex items-center">
											Title&nbsp;{sortIcon("title")}
										</div>
									</th>
									<th scope="col">Badge</th>
									<th scope="col" onClick={() => requestSort("users_count")}>
										<div className="flex items-center">
											User&nbsp;{sortIcon("users_count")}
										</div>
									</th>
									<th scope="col" onClick={() => requestSort("price")}>
										<div className="flex items-center">
											Price&nbsp;{sortIcon("price")}
										</div>
									</th>
									<th scope="col" onClick={() => requestSort("places_count")}>
										<div className="flex items-center">
											Places&nbsp;{sortIcon("places_count")}
										</div>
									</th>
									<th scope="col" onClick={() => requestSort("tasks_count")}>
										<div className="flex items-center">
											Tasks&nbsp;{sortIcon("tasks_count")}
										</div>
									</th>
									<th
										scope="col"
										className="text-center"
										onClick={() => requestSort("status")}
									>
										<div className="flex items-center">
											Status&nbsp;{sortIcon("status")}
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{quests?.length > 0 &&
									quests.map((quest) => (
										<tr key={quest.id}>
											<td>
												<Link to={`/quests/${quest.slug}`}>{quest.title}</Link>
											</td>
											<td>
												<Link to={`/quests/${quest.slug}`}>
													<div className="circle-img quest-badge">
														<img
															src={quest.badge?.image?.url}
															height={48}
															width={48}
															alt=""
														/>
													</div>
												</Link>
											</td>
											<td>
												<Link to={`/quests/${quest.slug}`}>
													{quest.users_count}
												</Link>
											</td>
											<td>
												<Link to={`/quests/${quest.slug}`}>
													{quest.price === 0.0 ? "Free" : `S$ ${quest.price}`}
												</Link>
											</td>
											<td>
												<Link to={`/quests/${quest.slug}`}>
													{quest.places_count}
												</Link>
											</td>
											<td>
												<Link to={`/quests/${quest.slug}`}>
													{quest.tasks_count}
												</Link>
											</td>
											<td className="text-center">
												<Link to={`/quests/${quest.slug}`}>
													<QuestStatus status={quest.status} />
												</Link>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					<Pagination
						isLoading={isFetching}
						total={total}
						currentPage={currentPage}
						perPage={perPage}
						onPageChange={onPageChange}
					/>
				</div>
			)}
		</>
	);
};

const SkeletonQuestList = () => {
	return (
		<table className="table">
			<tbody>
				{Array.from({ length: 7 }).map((_, index) => (
					<tr key={`skleton-quest-list-${index}`}>
						<td>
							<Skeleton width={200} />
						</td>
						<td>
							<Skeleton width={200} />
						</td>
						<td>
							<Skeleton width={200} />
						</td>
						<td>
							<Skeleton width={200} />
						</td>
						<td>
							<Skeleton width={200} />
						</td>
						<td>
							<Skeleton width={200} />
						</td>
						<td className="text-center">
							<Skeleton width={200} />
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default ListQuest;
